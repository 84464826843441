import {useSelector} from 'react-redux';
import React, {useEffect, useState} from 'react';
import spacetime from 'spacetime';
import _ from 'lodash';
import {
  useCreateDocumentMutation,
  useDeleteDocumentMutation,
  useGetDocumentQuery,
} from '../../data/documentWorkspaceApi';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';
import LoadingButton from '@mui/lab/LoadingButton';
import Loading from '../Loading';
import {BLACK_100, WHITE_100} from '../../theme';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import CheckIcon from '@mui/icons-material/Check';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import {CREATE_FLOWS, CREATE_STEPS, DOCUMENT_TYPES, ROLL_FORWARD_STATUSES} from '../../constants';
import {useNavigate} from 'react-router-dom';

const QUARTERS = [
  'Q1',
  'Q2',
  'Q3',
  'Q4',
];

const YEARS = [
  spacetime.now().subtract(1, 'year').format('year'),
  spacetime.now().format('year'),
  spacetime.now().add(1, 'year').format('year'),
]

const _getDocumentName = (documentType, base, fiscalYear, quarter) => {
  if (_.isNil(base) || _.isEmpty(base)) {
    console.warn('Document name base is empty');
  }
  switch (documentType) {
    case 'ANNUAL':
      if (_.isNil(base) || _.isEmpty(base)) {
        return `${fiscalYear} - Annual Report`;
      }
      return `${base} - ${fiscalYear} - Annual Report`;
    case 'QUARTER':
      if (_.isNil(base) || _.isEmpty(base)) {
        return `${quarter} ${fiscalYear} - Quarterly Report`;
      }
      return `${base} - ${quarter} ${fiscalYear} - Quarterly Report`;
    default:
      return base;
  }
}

const styles = {
  createButton: {
    background: '#38CF7A',
    borderRadius: '0.5rem',
    paddingLeft: '0.75rem',
    paddingRight: '1rem',
    paddingVertical: '0.625rem',
    height: '2.5rem',
    '&:hover': {
      backgroundColor: '#38CF7A',
    },
  },
  createButtonLabel: {
    fontSize: '0.875rem',
    lineHeight: '140%',
    fontWeight: '500',
  },
  cancelButton: {
    borderRadius: '0.5rem',
    paddingVertical: '0.6825rem',
    paddingHorizontal: '1rem',
    height: '2.5rem',
    '&:hover': {
      backgroundColor: WHITE_100,
    },
  },
  cancelButtonLabel: {
    color: '#043B34',
    fontSize: '0.875rem',
    fontWeight: '500',
    lineHeight: '130%',
  },
  createOption: {
    selected: {
      cursor: 'pointer',
      borderRadius: '0.5rem',
      border: '1px solid #DDE0DE',
      background: 'linear-gradient(0deg, #F3F4F4 0%, #F3F4F4 100%), linear-gradient(0deg, #FFF 0%, #FFF 100%), #E5F6EC',
    },
    disabled: {
      borderRadius: '0.5rem',
      border: '1px solid #DDE0DE',
      background: WHITE_100,
      opacity: 0.5,
    },
    default: {
      cursor: 'pointer',
      borderRadius: '0.5rem',
      border: '1px solid #DDE0DE',
      background: WHITE_100,
    },
    label: {
      color: '#043B34',
      fontSize: '0.875rem',
      fontWeight: '600',
      lineHeight: '140%',
    },
  },
  modal: {
    base: {
      borderRadius: '0.5rem',
      boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.15)',
      backgroundColor: WHITE_100,
      height: '100%',
    },
    title: {
      fontSize: '1.125rem',
      fontWeight: '700',
      lineHeight: '120%',
      color: '#043B34',
    },
    divider: {
      borderBottomWidth: '0.0625rem',
      opacity: 1,
      color: '#DDe0DE',
    },
    label: {
      fontSize: '0.8125rem',
      fontWeight: '500',
      lineHeight: '150%',
      color: '#043B34',
    },
    stepLabel: {
      color: '#043B34',
      opacity: 0.5,
      fontSize: '0.75rem',
      fontWeight: '500',
      lineHeight: '140%',
    },
    heavy: {
      fontSize: '0.8125rem',
      fontWeight: '700',
      lineHeight: '150%',
      color: '#043B34',
    },
  },
  processing: {
    processingLabel: {
      color: '#EC881B',
      fontSize: '0.75rem',
      fontWeight: '400',
      lineHeight: '140%',
    },
    completedLabel: {
      color: '#043B34',
      fontSize: '0.75rem',
      fontWeight: '400',
      lineHeight: '140%',
    },
    failedLabel: {
      color: '#043B34',
      fontSize: '0.75rem',
      fontWeight: '400',
      lineHeight: '140%',
    },
    label: {
      color: '#043B34',
      fontSize: '0.75rem',
      fontWeight: '500',
      lineHeight: '140%',
    },
  },
}

const Processing = ({isSuccess, isError}) => {
  if (isSuccess) {
    return (
      <Stack direction={'row'} spacing={'0.5rem'} width={'5.5rem'} alignItems={'center'} justifyContent={'flex-end'}>
        <CheckIcon fontSize={'1.25rem'} sx={{color: '#25C489'}}/>
        <Typography style={styles.processing.completedLabel}>Completed</Typography>
      </Stack>
    );
  } else if (isError) {
    return (
      <Stack direction={'row'} spacing={'0.5rem'} width={'5.5rem'} alignItems={'center'} justifyContent={'flex-end'}>
        <ErrorOutlineIcon fontSize={'1.25rem'} sx={{color: '#f44336'}}/>
        <Typography style={styles.processing.failedLabel}>Failed</Typography>
      </Stack>
    );
  }
  return (
    <Stack direction={'row'} spacing={'0.5rem'} width={'5.5rem'} alignItems={'center'} justifyContent={'flex-end'}>
      <CircularProgress size={'1.25rem'} sx={{color: '#EC881B'}}/>
      <Typography style={styles.processing.processingLabel}>Processing</Typography>
    </Stack>
  );
}

Processing.propTypes = {
  isProcessing: PropTypes.bool.isRequired,
  isSuccess: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
}

const ProcessingContainer = ({documentId, onComplete}) => {
  const {isAuthenticated} = useSelector((state) => state.session);
  const [skip, setSkip] = useState(true);
  const [numFailures, setNumFailures] = useState(0);

  useEffect(() => {
    setSkip(!open || _.isNil(documentId) || !isAuthenticated)
  }, [documentId, open, isAuthenticated]);

  const {data, isLoading, isError, isSuccess} = useGetDocumentQuery({documentId}, {refetchOnMountOrArgChange: true, skip, pollingInterval: 5000});

  useEffect(() => {
    if (isSuccess && _.includes([ROLL_FORWARD_STATUSES.completed, ROLL_FORWARD_STATUSES.failed], _.get(data, 'record.rollForwardStatus'))) {
      setSkip(true);

      if (_.get(data, 'record.rollForwardStatus') === ROLL_FORWARD_STATUSES.completed) {
        onComplete();
      }
    }
  }, [isSuccess, data]);

  useEffect(() => {
    if (isError && numFailures === 12) {
      console.error('Too many failures');
      setSkip(true);
    } else {
      setNumFailures(numFailures + 1);
    }
  }, [isError]);

  return (
    <Box width={'100%'} display={'flex'} flex={1} flexDirection={'column'} justifyContent={'center'}>
      <List width={'100%'}>
        <ListItem divider={true}>
          <Stack direction={'row'} flex={1} spacing={'0.5rem'} width={'100%'} justifyContent={'space-between'} height={'1.5rem'} alignItems={'center'}>
            <Typography style={styles.processing.label}>Rolling forward dates</Typography>
            <Processing isProcessing={isLoading || _.get(data, 'record.rollForwardStatus') === 'PROCESSING'} isSuccess={_.get(data, 'record.rollForwardStatus') === 'COMPLETED'} isError={_.get(data, 'record.rollForwardStatus') === 'FAILED'} />
          </Stack>
        </ListItem>
        <ListItem divider={true}>
          <Stack direction={'row'} flex={1} spacing={'0.5rem'} width={'100%'} justifyContent={'space-between'} height={'1.5rem'} alignItems={'center'}>
            <Typography style={styles.processing.label}>Rolling forward tables</Typography>
            <Processing isProcessing={isLoading || _.get(data, 'record.rollForwardStatus') === 'PROCESSING'} isSuccess={_.get(data, 'record.rollForwardStatus') === 'COMPLETED'} isError={_.get(data, 'record.rollForwardStatus') === 'FAILED'} />
          </Stack>
        </ListItem>
        <ListItem divider={true}>
          <Stack direction={'row'} flex={1} spacing={'0.5rem'} width={'100%'} justifyContent={'space-between'} height={'1.5rem'} alignItems={'center'}>
            <Typography style={styles.processing.label}>Rolling forward disclosures</Typography>
            <Processing isProcessing={isLoading || _.get(data, 'record.rollForwardStatus') === 'PROCESSING'} isSuccess={_.get(data, 'record.rollForwardStatus') === 'COMPLETED'} isError={_.get(data, 'record.rollForwardStatus') === 'FAILED'} />
          </Stack>
        </ListItem>
        <ListItem divider={true}>
          <Stack direction={'row'} flex={1} spacing={'0.5rem'} width={'100%'} justifyContent={'space-between'} height={'1.5rem'} alignItems={'center'}>
            <Typography style={styles.processing.label}>Rolling tie-out support workbook</Typography>
            <Processing isProcessing={isLoading || _.get(data, 'record.rollForwardStatus') === 'PROCESSING'} isSuccess={_.get(data, 'record.rollForwardStatus') === 'COMPLETED'} isError={_.get(data, 'record.rollForwardStatus') === 'FAILED'} />
          </Stack>
        </ListItem>
      </List>
    </Box>
  )
};

ProcessingContainer.propTypes = {
  documentId: PropTypes.string,
  onComplete: PropTypes.func,
}

const CreateDocumentModal = ({open, onClose, onError, previousDocuments = [], previousDocumentId, createFlow, existingDocumentId, onRollForwardComplete}) => {
  const navigate = useNavigate();
  const {orgConfig} = useSelector((state) => state.session);
  const [loading, setLoading] = useState(false);
  const [quarter, setQuarter] = useState(spacetime.now().format('quarter'));
  const [fiscalYear, setFiscalYear] = useState(spacetime.now().format('year'));
  const [documentType, setDocumentType] = useState(null);
  const [documentName, setDocumentName] = useState(_getDocumentName(documentType, _.get(orgConfig, 'companyLegalName', orgConfig?.name), fiscalYear, quarter));
  const [documentNameIsDirty, setDocumentNameIsDirty] = useState(false);
  const [documentNameError, setDocumentNameError] = useState(false);
  const [documentNameErrorMessage, setDocumentNameErrorMessage] = useState('');
  const [step, setStep] = useState(null);
  const [createDocument, {isSuccess, isError, error, reset, data}] = useCreateDocumentMutation();
  const [deleteDocument, {isLoading: deleteDocumentLoading, isSuccess: deleteDocumentSuccess, isError: deleteDocumentError, reset: deleteDocumentReset}] = useDeleteDocumentMutation();
  const [documentId, setDocumentId] = useState(existingDocumentId);
  const [previousDocument, setPreviousDocument] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isRollForwardComplete, setIsRollForwardComplete] = useState(false);
  const [years, setYears] = useState(YEARS);

  const _onClose = () => {
    onClose();
  }

  const _getReportType = () => {
    switch (createFlow) {
      case CREATE_FLOWS.annualRollForward:
      case CREATE_FLOWS.annualBlankDocument:
        return DOCUMENT_TYPES.annualReport;
      case CREATE_FLOWS.quarterly:
        return DOCUMENT_TYPES.quarterlyReport;
      default:
        return DOCUMENT_TYPES.other;
    }
  }

  const _getPreviousDocument = () => {
    if (createFlow === CREATE_FLOWS.annualRollForward) {
      const _existingDocument = _.find(previousDocuments, {documentId: existingDocumentId});
      if (!_.isNil(_existingDocument)) {
        return _.find(previousDocuments, {documentId: _existingDocument.previousDocumentId});
      } else if (!_.isNil(previousDocumentId)) {
        return _.find(previousDocuments, {documentId: previousDocumentId});
      }
      return previousDocuments[0];
    }

    return null
  }

  const _buildYearsList = previousFiscalYear => {
    let _years = YEARS;
    if (!_.includes(_years, previousFiscalYear)) {
      _years = _.sortBy([..._years, previousFiscalYear]);
    }
    return _years;
  }

  useEffect(() => {
    if (open) {
      const _quarter = spacetime.now().format('quarter');
      const _documentType = _getReportType();
      const _previousDocument = _getPreviousDocument();
      let _fiscalYear = spacetime.now().format('year');
      if (!_.isNil(_previousDocument)) {
        _fiscalYear = `${Number(_previousDocument.fiscalYear) + 1}`;
      }
      setDocumentType(_documentType);
      setQuarter(_documentType === DOCUMENT_TYPES.quarterlyReport ? _quarter : null);
      setFiscalYear(_.includes([DOCUMENT_TYPES.annualReport, DOCUMENT_TYPES.quarterlyReport], _documentType) ? _fiscalYear : null);
      setDocumentName(_getDocumentName(_documentType, _.get(orgConfig, 'companyLegalName', orgConfig?.name), _fiscalYear, _quarter));
      setDocumentNameIsDirty(false);
      setDocumentNameError(false);
      setDocumentNameErrorMessage('');
      setStep(createFlow === CREATE_FLOWS.annualRollForward ? _.isNil(existingDocumentId) ? CREATE_STEPS.create : CREATE_STEPS.processing : null);
      setDocumentId(existingDocumentId);
      setPreviousDocument(_previousDocument);
      setYears(_buildYearsList(_fiscalYear));
      setIsRollForwardComplete(false);
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [createFlow, existingDocumentId, open]);

  useEffect(() => {
    if (orgConfig && !documentNameIsDirty) {
      setDocumentName(_getDocumentName(documentType, documentType === DOCUMENT_TYPES.other ? '' : _.get(orgConfig, 'companyLegalName', orgConfig.name), fiscalYear, quarter));
    }
  }, [orgConfig, documentType, fiscalYear, documentNameIsDirty]);

  useEffect(() => {
    if (isSuccess) {
      if (createFlow === CREATE_FLOWS.annualRollForward) {
        setStep(CREATE_STEPS.processing);
        setDocumentId(_.get(data, 'documentId'));
      } else {
        _onClose();
      }
    }
    reset();
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      console.error('Error creating document', {error});
      if (_.get(error, 'data.code') === 'DOCUMENT_EXISTS') {
        if (documentType !== DOCUMENT_TYPES.annualReport) {
          setDocumentNameError(true);
          setDocumentNameErrorMessage('Filename exists. Please choose another.');
        } else {
          onError('Filename exists. Please choose another.');
        }
      } else {
        onError();
      }
      reset();
    }
  }, [isError]);

  useEffect(() => {
    if (deleteDocumentSuccess) {
      _onClose();
      deleteDocumentReset();
    }
  }, [deleteDocumentSuccess]);

  useEffect(() => {
    if (deleteDocumentError) {
      console.error('Error deleting document', {deleteDocumentError});
      onError('There was a problem processing your request. Please try again later.');
      deleteDocumentReset();
    }
  }, [deleteDocumentError]);

  const _getModalTitle = () => {
    switch (documentType) {
      case DOCUMENT_TYPES.annualReport:
        if (createFlow === CREATE_FLOWS.annualRollForward) {
          return 'annual report (from roll forward)';
        }
        return 'annual report';
      case DOCUMENT_TYPES.quarterlyReport:
        return 'quarterly report';
      default:
        return 'basic document';
    }
  }

  const _handleChange = (field) => (event) => {
    const _value = event.target.value;
    switch (field) {
      case 'quarter':
        setQuarter(_value);
        if (!documentNameIsDirty) {
          setDocumentName(_getDocumentName(documentType, _.get(orgConfig, 'companyLegalName', orgConfig.name), fiscalYear, _value))
        }
        break;
      case 'fiscalYear':
        setFiscalYear(_value);
        if (!documentNameIsDirty) {
          setDocumentName(_getDocumentName(documentType, _.get(orgConfig, 'companyLegalName', orgConfig.name), _value, quarter))
        }
        break;
      case 'previousDocument': {
        const _previousDocument = _.find(previousDocuments, {documentId: _value});
        setPreviousDocument(_previousDocument);
        if (!_.isNil(_previousDocument)) {
          const _fiscalYear = `${Number(_previousDocument.fiscalYear) + 1}`;
          setFiscalYear(_fiscalYear);
          setYears(_buildYearsList(_fiscalYear));
        }
        break;
      }
      default:
        setDocumentName(_value);
        setDocumentNameIsDirty(true);
    }
  }

  const _renderPeriod = () => {
    if (_.includes([DOCUMENT_TYPES.annualReport, DOCUMENT_TYPES.quarterlyReport], documentType)) {
      return (
        <Stack direction={'row'} spacing={'1rem'}>
          <Stack direction={'column'} spacing={'0.5rem'}>
            <Typography style={styles.modal.label}>Fiscal year</Typography>
            <FormControl>
              <Select
                id="createDocument-fiscalYear-select"
                value={fiscalYear}
                onChange={_handleChange('fiscalYear')}
              >
                {_.map(years, _year => <MenuItem key={_year} value={_year}>{_year}</MenuItem>)}
              </Select>
            </FormControl>
          </Stack>
          {documentType === DOCUMENT_TYPES.quarterlyReport && (
            <Stack direction={'column'} spacing={'0.5rem'}>
              <Typography style={styles.modal.label}>Quarter</Typography>
              <FormControl>
                <Select
                  id="createDocument-quarter-select"
                  value={quarter}
                  onChange={_handleChange('quarter')}
                >
                  {_.map(QUARTERS, _quarter => <MenuItem key={_quarter} value={_quarter}>{_quarter}</MenuItem>)}
                </Select>
              </FormControl>
            </Stack>
          )}
        </Stack>
      );
    }
  }

  const _renderDocumentName = () => {
    return (
      <Stack direction={'column'} spacing={'0.5rem'}>
        <Typography style={styles.modal.label}>Document name</Typography>
        <TextField id="createDocument-documentName" variant="outlined" value={documentName} onChange={_handleChange('documentName')} error={documentNameError} helperText={documentNameErrorMessage} />
      </Stack>
    );
  }

  const _createDocument = async () => {
    setDocumentNameError(false);
    setDocumentNameErrorMessage('');
    setLoading(true);
    const _payload = {
      name: documentName,
      type: documentType,
      status: 'DRAFT',
      fiscalYear,
      quarter,
    }

    if (createFlow === CREATE_FLOWS.annualRollForward) {
      _payload.fiscalYear = fiscalYear;
      _payload.previousDocumentId = _.get(previousDocument, 'documentId');
      _payload.isRollForward = true;
      _payload.rollForwardStatus = 'NOT_STARTED';
      _payload.status = 'PENDING';
    }

    await createDocument(_.omitBy(_payload, _.isNil));
    setLoading(false);
  }

  const _validateInputs = () => {
    switch (documentType) {
      case DOCUMENT_TYPES.annualReport:
        return !_.isEmpty(fiscalYear) && !_.isEmpty(documentName);
      case DOCUMENT_TYPES.quarterlyReport:
        return !_.isEmpty(fiscalYear) && !_.isEmpty(quarter) && !_.isEmpty(documentName);
      default:
        return !_.isEmpty(documentName);
    }
  }

  const _renderModalTitle = () => {
    return `Create ${_getModalTitle()}`;
  }

  const _renderPreviousDocumentList = () => {
    if (createFlow !== CREATE_FLOWS.annualRollForward) {
      return null;
    }
    return (
      <Stack direction={'column'} spacing={'0.5rem'}>
        <Typography style={styles.modal.label}>Report to roll forward</Typography>
        <FormControl>
          <Select
            id="createDocument-previousDocument-select"
            value={_.get(previousDocument, 'documentId')}
            onChange={_handleChange('previousDocument')}
          >
            {_.map(previousDocuments, _document => <MenuItem key={_document.documentId} value={_document.documentId}>{_document.name}</MenuItem>)}
          </Select>
        </FormControl>
      </Stack>
    )
  }

  const _onRollForwardComplete = () => {
    setIsRollForwardComplete(true);
    onRollForwardComplete();
  }

  const _renderCreate = () => {
    if (createFlow === CREATE_FLOWS.annualRollForward && step === CREATE_STEPS.processing) {
      return (
        <Stack direction={'column'} justifyContent={'space-between'} flex={1}>
          <React.Fragment>
            <Typography style={styles.modal.heavy}>We&apos;ve initiated the roll forward of your {_.get(previousDocument, 'fiscalYear')} report.</Typography>
            <Typography style={styles.modal.label}>This can take several minutes&mdash;we’ll email you when the process is complete.</Typography>
          </React.Fragment>
          <ProcessingContainer documentId={documentId} onComplete={_onRollForwardComplete} />
        </Stack>
      )
    } else {
      return (
        <React.Fragment>
          {_renderPreviousDocumentList()}
          {_renderPeriod()}
          {_renderDocumentName()}
        </React.Fragment>
      );
    }
  }

  const _deleteDocument = async () => {
    await deleteDocument({
      documentId,
    });
    onClose();
  }

  const _renderCTAButton = () => {
    if (createFlow === CREATE_FLOWS.annualRollForward && step === CREATE_STEPS.processing && !isRollForwardComplete) {
      return (
        <Button variant={'contained'} sx={{...styles.createButton, width: '9.3125rem'}} onClick={_onClose} disabled={!_validateInputs()}><Typography style={styles.createButtonLabel}>Close</Typography></Button>
      )
    } else if (createFlow === CREATE_FLOWS.annualRollForward && isRollForwardComplete) {
      return (
        <Button variant={'contained'} sx={{...styles.createButton, width: '9.3125rem'}} onClick={() => navigate(`/documents/${documentId}`)} disabled={!_validateInputs()}><Typography style={styles.createButtonLabel}>Open</Typography></Button>
      )
    } else {
      return (
        <LoadingButton variant={'contained'} sx={{...styles.createButton, width: '9.3125rem'}} onClick={_createDocument} disabled={!_validateInputs()} loading={loading} loadingIndicator={<Loading loading={loading} loadingProps={{color: BLACK_100}} />}><Typography style={styles.createButtonLabel}>Create document</Typography></LoadingButton>
      )
    }
  }

  const _renderCancelButton = () => {
    if (createFlow === CREATE_FLOWS.annualRollForward && step === CREATE_STEPS.processing && !isRollForwardComplete) {
      return <LoadingButton variant={'outlined'} sx={styles.cancelButton} onClick={_deleteDocument} loading={deleteDocumentLoading} loadingIndicator={<Loading loading={deleteDocumentLoading} loadingProps={{color: BLACK_100}} />}><Typography style={styles.cancelButtonLabel}>{!deleteDocumentLoading ? 'Cancel roll forward' : null}</Typography></LoadingButton>;
    }

    return <Button variant={'outlined'} sx={styles.cancelButton} onClick={_onClose}><Typography style={styles.cancelButtonLabel}>Cancel</Typography></Button>;
  }

  if (!isOpen) {
    return null;
  }

  return (
    <Modal open={open} onClose={_onClose}>
      <Box position={'absolute'} sx={{left: '50%', top: '50%', transform: 'translate(-50%, -50%)'}}>
        <Box width={'28rem'} minWidth={'28rem'} maxWidth={'28rem'} height={'27rem'} minHeight={'27rem'} maxHeight={'27rem'}>
          <Stack direction={'column'} sx={styles.modal.base}>
            <Stack direction={'row'} paddingY={'1rem'} paddingLeft={'1.5rem'} paddingRight={'1rem'} justifyContent={'space-between'} alignItems={'center'}>
              <Typography sx={styles.modal.title}>{_renderModalTitle()}</Typography>
              <IconButton onClick={_onClose}><CloseIcon /></IconButton>
            </Stack>
            <Divider orientation={'horizontal'} sx={styles.modal.divider} />
            <Stack direction={'column'} paddingTop={'1.25rem'} paddingBottom={'1.5rem'} paddingX={'1.5rem'} spacing={'1.25rem'} flex={1} justifyContent={'flex-start'}>
              {_renderCreate()}
            </Stack>
            <Divider orientation={'horizontal'} sx={styles.modal.divider} />
            <Stack direction={'row'} paddingY={'1rem'} paddingLeft={'1.5rem'} paddingRight={'1rem'} justifyContent={'flex-end'} alignItems={'center'} spacing={'0.5rem'}>
              {_renderCancelButton()}
              {_renderCTAButton()}
            </Stack>
          </Stack>
        </Box>
      </Box>
    </Modal>
  )
}

CreateDocumentModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  previousDocuments: PropTypes.array,
  previousDocumentId: PropTypes.string,
  createFlow: PropTypes.string,
  existingDocumentId: PropTypes.string,
  onRollForwardComplete: PropTypes.func,
}

export default CreateDocumentModal;
