import store from './store';

const baseUrl = process.env.REACT_APP_LIVEBLOCKS_API_URL;

const authEndpoint = async (room) => {
  const {session} = store.getState();

  if (!session || !session.accessToken) {
    throw new Error('Authorization token is missing');
  }

  const headers = {
    Authorization: `Bearer ${session.accessToken}`,
    'x-inscope-org': session.currentOrg.orgId,
    'Content-Type': 'application/json',
  };

  const body = JSON.stringify({room});
  const response = await fetch(`${baseUrl}/api/liveblocksAuth`, {
    method: 'POST',
    headers,
    body,
  });

  return await response.json();
};

// Used only for Comments and Notifications. Return a list of user information
// retrieved from `userIds`. This info is used in comments, mentions etc.
// Return in same order as userIds argument, and include only the meta info
const resolveUsers = async ({userIds}) => {
  const {session} = store.getState();

  if (!session || !session.accessToken) {
    throw new Error('Authorization token is missing');
  }

  const headers = {
    Authorization: `Bearer ${session.accessToken}`,
    'x-inscope-org': session.currentOrg.orgId,
    'Content-Type': 'application/json',
  };

  const searchParams = new URLSearchParams(
    userIds.map((userId) => ['userIds', userId]),
  );

  const response = await fetch(`${baseUrl}/api/liveblocksUsers?${searchParams}`, {
    method: 'GET',
    headers,
  });

  const userData = await response.json();

  // Generate avatar URLs
  userData.forEach(user => {
    user.avatar = generateAvatarUrl(user.name, user.color);
  });

  return userData;
};

// Used only for Comments. Return a list of userIds that match `text`.
// These userIds are used to create a mention list when typing in the
// composer.
const resolveMentionSuggestions = async ({text}) => {
  const {session} = store.getState();

  if (!session || !session.accessToken) {
    throw new Error('Authorization token is missing');
  }

  const headers = {
    Authorization: `Bearer ${session.accessToken}`,
    'x-inscope-org': session.currentOrg.orgId,
    'Content-Type': 'application/json',
  };

  const response = await fetch(`${baseUrl}/api/liveblocksUsers/search?text=${encodeURIComponent(text)}`, {
    method: 'GET',
    headers,
  });

  return await response.json();
};

const resolveRoomsInfo = async ({roomIds}) => {
  return []; // No additional logic for rooms info
};

const throttle = 100;

const generateAvatarUrl = (name, backgroundColor) => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  const size = 80; // We'll use this for both width and height
  canvas.width = size;
  canvas.height = size;

  // Draw circular background
  context.beginPath();
  context.arc(size / 2, size / 2, size / 2, 0, 2 * Math.PI);
  context.fillStyle = backgroundColor;
  context.fill();

  // Draw text
  context.font = 'bold 38px Arial, sans-serif';
  context.fillStyle = 'white';
  context.textAlign = 'center';
  context.textBaseline = 'middle';
  context.fillText(name.charAt(0).toUpperCase(), size / 2, size / 2 + 2); // +2 for slight vertical adjustment

  return canvas.toDataURL('image/png');
}

export {authEndpoint, resolveUsers, resolveMentionSuggestions, resolveRoomsInfo, throttle};
