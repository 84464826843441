import React, {useEffect, useState} from 'react';
import _ from 'lodash';
import MenuItem from '@mui/material/MenuItem';
import PropTypes from 'prop-types';
import {
  usePopupState,
  bindTrigger,
  bindMenu,
} from 'material-ui-popup-state/hooks'
import Menu from '@mui/material/Menu';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {GREEN_70, GREEN_80, GREEN_95, WHITE_100} from '../App';
import {styled} from '@mui/material';
import {useSelector} from 'react-redux';
import CheckIcon from '@mui/icons-material/Check';

const AppBarSelect = ({onChange, id, startIcon, data, loading, isError, placeholder, optional, minWidth, selectedPeriod}) => {
  // const periods = useSelector((state) => state.periods);
  // const {reportingPeriods} = periods;
  const {currentOrg} = useSelector((state) => state.session);
  const [value, setValue] = useState(optional || _.isNil(data) || _.isEmpty(data) ? {value: '', label: ''} : data[0]);
  const popupState = usePopupState({variant: 'popover', popupId: id});

  const StyledMenu = styled(Menu)(() => ({
    '& .MuiList-root': {
      padding: 0,
      margin: 0,
    },
    '& .MuiPaper-root': {
      backgroundColor: GREEN_95,
      minWidth: '10.5rem',
      padding: '0.5rem',
      borderRadius: '0.375rem',
      maxHeight: '18.75rem',
      overflowY: 'auto',
    },
    '& .MuiMenuItem-root:hover': {
      backgroundColor: 'rgba(211, 243, 199, 0.25)',
      borderRadius: '0.375rem',
      fontWeight: '700',
    },
    '& .MuiMenuItem-root': {
      color: GREEN_80,
    },
  }));

  useEffect(() => {
    setValue(optional || _.isNil(data) || _.isEmpty(data) ? {value: '', label: ''} : data[0]);
  }, [loading]);

  useEffect(() => {
    setValue(optional || _.isNil(data) || _.isEmpty(data) ? {value: '', label: ''} : data[0]);
  }, [currentOrg])

  const handleChange = (value) => {
    setValue(value);
    onChange(value.value);
  }

  let _data = data;
  if (optional && value.value !== '') {
    _data = [{value: '', label: 'Remove'}, ...data];
  }

  const renderCheckbox = (period, selectedPeriod) => {
    if (period === selectedPeriod) {
      return (
        <Box marginRight={'0.5rem'}>
          <CheckIcon fontSize={'1rem'} sx={{color: WHITE_100}} />
        </Box>
      )
    }
    return (
      <Box width={'1rem'} height={20} marginRight={'0.5rem'} />
    )
  }

  return (
    <React.Fragment>
      <Box minWidth={minWidth || '10rem'}>
        <LoadingButton
          variant="outlined"
          fullWidth={true}
          sx={{
            backgroundColor: popupState.isOpen ? GREEN_70 : WHITE_100,
          }}
          startIcon={startIcon}
          endIcon={popupState.isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          disabled={_.isNil(data) || data.length === 0}
          {...bindTrigger(popupState)}
          loading={loading}
        >
          <Box flex={1} display={'flex'}>{!_.isNil(value) && !_.isEmpty(value) && value.value !== '' ? value.label : placeholder}</Box>
        </LoadingButton>
        <StyledMenu {...bindMenu(popupState)}>
          {!isError && _.map(_data, (_period, _idx) => {
            return <MenuItem sx={{paddingLeft: 0}} value={_period.value} key={_idx} onClick={() => {
              handleChange(_period);
              popupState.close();
            }}>
              {renderCheckbox(_period.value, selectedPeriod)}
              <Box sx={{paddingLeft: _period.paddingLeft}}>
                {_period.label}
              </Box>
            </MenuItem>
          })}
        </StyledMenu>
      </Box>
    </React.Fragment>
  )
}

AppBarSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  startIcon: PropTypes.node.isRequired,
  data: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
  placeholder: PropTypes.string.isRequired,
  optional: PropTypes.bool,
  minWidth: PropTypes.string,
  selectedPeriod: PropTypes.string,
}

export default AppBarSelect;
