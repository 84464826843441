import React, {useEffect, useState, forwardRef} from 'react'
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import {connect} from 'react-redux';
import Box from '@mui/material/Box';
import {withRequiredAuthInfo} from '@propelauth/react';
import {GREEN_70} from '../App';
import _ from 'lodash';
import {withLogger, LoggerProps} from '../components/LoggingWrapper';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {TableVirtuoso} from 'react-virtuoso';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import {
  FormControl,
  InputLabel, MenuItem,
  Select,
  TableSortLabel,
} from '@mui/material';
import {visuallyHidden} from '@mui/utils';
import CompanyConfigTable from '../components/CompanyConfigTable';
import CommonPageWrapper from '../components/CommonPageWrapper';
import {LINE_ITEM_TYPES} from '../constants';
import {useGetPresentationItemsQuery} from '../data/api';
import AppPage from './AppPage';

const _filterRecords = (records, pliTypeFilter) => {
  if (pliTypeFilter === 'ALL') {
    return records;
  }

  return _.filter(records, {pliType: pliTypeFilter});
}

const _sortRecords = (records, order, orderBy) => {
  return order === 'asc' ? _.sortBy(records, [orderBy]) : _.reverse(_.sortBy(records, [orderBy]))
}

class PresentationItemsPage extends AppPage {
  getPageTitle = () => 'Presentation Items';
  renderBodyContent = () => <PresentationItemsPageComponent logger={this.props.logger} accessToken={this.props.accessToken}/>;
}

const PresentationItemsPageComponent = ({logger}) => {
  const [filteredRecords, setFilteredRecords] = useState([]);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('pliType');
  const [pliTypeFilter, setPLITypeFilter] = useState('ALL');
  const {data: plis, isFetching, isError} = useGetPresentationItemsQuery();

  useEffect(() => {
    logger.debug('PLIs loaded', {plis});
    const _sortedPLIs = _sortRecords(plis, order, orderBy);
    setFilteredRecords(_filterRecords(_sortedPLIs, pliTypeFilter));
  }, [plis])

  useEffect(() => {
    setFilteredRecords(_sortRecords(filteredRecords, order, orderBy));
  }, [order, orderBy]);

  useEffect(() => {
    setFilteredRecords(_sortRecords(_filterRecords(plis, pliTypeFilter), order, orderBy));
  }, [pliTypeFilter]);

  const VTCScroller = forwardRef((props, ref) => (
    <TableContainer {...props} ref={ref} />
  ));
  VTCScroller.displayName = 'Scroller';
  const VTCTableBody = forwardRef((props, ref) => <TableBody {...props} ref={ref} />);
  VTCTableBody.displayName = 'TableBody';
  const VTCTableRow = ({item: _item, ...props}) => <TableRow {...props} />;
  VTCTableRow.propTypes = {
    item: PropTypes.any.isRequired,
  };

  const VirtuosoTableComponents = {
    displayName: 'VirtuosoTableComponents',
    Scroller: VTCScroller,
    Table: (props) => (
      <Table {...props} sx={{borderCollapse: 'separate', tableLayout: 'fixed', minHeight: 'auto', height: 'auto'}} />
    ),
    TableHead,
    TableRow: VTCTableRow,
    TableBody: VTCTableBody,
  };

  const _headers = [
    {
      id: 'name',
      label: 'FSLI',
    },
    {
      id: 'pliType',
      label: 'Report',
    },
  ]

  const handleRequestSort = (property) => () => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  }

  const handleFilterChange = event => {
    setPLITypeFilter(event.target.value);
  }

  const fixedHeaderContent = () => {
    return (
      <TableRow>
        {_.map(_headers, _header =>
          <TableCell
            key={_header.id}
            sortDirection={orderBy === _header.id ? order : false}
            variant={'head'}
            align={'left'}
            datatestid={`tableHeader-${_header.id}`}
            sx={{
              borderBottom: 1,
            }}
          >
            <TableSortLabel
                active={orderBy === _header.id}
                direction={orderBy === _header.id ? order : 'asc'}
                onClick={handleRequestSort(_header.id)}>
              <Typography variant={'body2'}>{_header.label}</Typography>
              {orderBy === _header.id
                ? (
                      <Box component={'span'} sx={visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </Box>
                  )
                : null}
            </TableSortLabel>
          </TableCell>,
        )}
      </TableRow>
    );
  }

  const rowContent = (_index, row) => {
    return (
      <React.Fragment key={_index}>
        <TableCell>
          <Typography>{row.name}</Typography>
        </TableCell>
        <TableCell>
          <Typography>{LINE_ITEM_TYPES[row.pliType]}</Typography>
        </TableCell>
      </React.Fragment>
    );
  }

  return (
    <CommonPageWrapper>
      <CompanyConfigTable
        hasError={isError}
        errorMessage={"There was an error loading your data. We're on it!"}
        loading={isFetching}
        screen={'FSLI'}
        success={!isError}
        hasData={!_.isEmpty(plis)}>
        <Box width={'100%'} display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'flex-end'} paddingTop={'1.5rem'} paddingRight={'1.5rem'} sx={{backgroundColor: GREEN_70}}>
          <Typography>Show for report</Typography>
          <FormControl sx={{marginLeft: '12px', width: '200px'}}>
            <InputLabel id={'pliType-filter-label'}>Report Type</InputLabel>
            <Select
              labelId={'pliType-filter-label'}
              id={'pliType-select'}
              value={pliTypeFilter}
              label={'Report Type'}
              onChange={handleFilterChange}
            >
              <MenuItem value={'ALL'}>All</MenuItem>
              {
                _.map(LINE_ITEM_TYPES, (value, key) =>
                  <MenuItem key={key} value={key}>{value}</MenuItem>,
                )
              }
            </Select>
          </FormControl>
        </Box>
        <TableVirtuoso
          data={filteredRecords}
          components={VirtuosoTableComponents}
          fixedHeaderContent={fixedHeaderContent}
          itemContent={rowContent}
        />
      </CompanyConfigTable>
    </CommonPageWrapper>
  )
}

PresentationItemsPageComponent.propTypes = {
  accessToken: PropTypes.string.isRequired,
  session: PropTypes.objectOf({
    currentOrg: PropTypes.objectOf({
      orgId: PropTypes.string.isRequired,
    }),
  }),
  logger: LoggerProps,
}

const mapStateToProps = (state) => {
  return {
    session: state.session,
  }
}

export default connect(mapStateToProps)(withRequiredAuthInfo(withLogger(PresentationItemsPage)));
