import React, {useState, useEffect} from 'react'
import {connect, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {withRequiredAuthInfo} from '@propelauth/react';

import {useGetAccountingTopicsQuery} from '../data/disclosuresApi';

// MUI
import {
  Box,
  Button,
  Card,
  Grid,
  IconButton,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

// Custom Components
import AppPage from './AppPage';
import CommonPageWrapper from '../components/CommonPageWrapper';

// Icons
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '../assets/search.svg';
import EmptyIcon from '../assets/empty-disclosures.svg';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

// Util
import PropTypes from 'prop-types';
import {BLACK_100, GREEN_60} from '../App';
import _ from 'lodash';

class DisclosureGuidePage extends AppPage {
  getPageTitle = () => 'Disclosure library';
  renderBodyContent = () => <DisclosureGuidePageComponent />;
}

const SearchInput = ({onSearch, query, setQuery}) => {
  const handleClear = () => {
    setQuery('');
    onSearch('');
  };

  return (
    <TextField
      value={query}
      onChange={(e) => {
        setQuery(e.target.value);
        onSearch(e.target.value);
      }}
      sx={{
        '& .MuiInputBase-root': {
          background: '#fff',
          borderRadius: '4px',
          height: '40px',
          width: '288px',
        },
        '.MuiInputBase-input': {
          fontSize: '0.875rem',
          fontWeight: 400,
        },
        '& .MuiOutlinedInput-root': {
          '&.Mui-focused fieldset': {
            borderColor: GREEN_60,
          },
        },
        '& fieldset': {
          borderRadius: '4px',
        },
      }}
      InputProps={{
        startAdornment: (
          <Box mr={2} pb={'3px'}>
            <img src={SearchIcon} height={16} width={16} alt={'search'} />
          </Box>
        ),
        endAdornment: (
          <>
            {query.length > 0 &&
              <Box sx={{p: 0, minHeight: 0, maxHeight: 0}} component={Button} onClick={() => handleClear()}>
                <CloseIcon sx={{ml: 5, fontSize: '20px', color: BLACK_100}} />
              </Box>
            }
          </>
        ),
      }}
      variant='outlined'
      placeholder='Search disclosures'
    />
  );
};

SearchInput.propTypes = {
  onSearch: PropTypes.func.isRequired,
  query: PropTypes.string.isRequired,
  setQuery: PropTypes.func.isRequired,
};

const TopicCard = ({topic}) => {
  const cardStyles = {
    border: '1px solid #DDE0DE',
    borderRadius: '8px',
    boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.10)',
    padding: '20px',
    height: 160,
    '&:hover': {
      border: `2px solid ${GREEN_60}`,
    },
  };

  return (
    <Link to={`/disclosure-library/${topic.accounting_topic_id}`} style={{textDecoration: 'none'}}>
      <Card sx={cardStyles}>
        <Typography variant='h3'>
          {topic.accounting_topic}
        </Typography>
      </Card>
    </Link>
  );
}

TopicCard.propTypes = {
  topic: PropTypes.shape({
    accounting_topic_id: PropTypes.string.isRequired,
    accounting_topic: PropTypes.string.isRequired,
  }).isRequired,
};

const EmptySearch = () => {
  const buttonStyles = {
    mt: 3,
    width: 300,
    height: 40,
    borderRadius: '4px',
    border: '1px solid #0F1E24',
  };

  return (
    <Box pt={15} display='flex' flexDirection='column' alignItems='center' justifyContent='center'>
      <img src={EmptyIcon} height={280} width={280} alt={'no data'} />
      <Typography mt={3} variant='h2' color={BLACK_100}>Can&apos;t find what you&apos;re looking for?</Typography>
      <Box maxWidth={600} mt={2}>
        <Typography variant='body2' fontSize={16} color={BLACK_100} textAlign='center' sx={{opacity: 0.8, lineHeight: '24px'}}>
          Make sure to check the spelling of the item you are searching for. You can also email our support team and ask for help with this disclosure type.
        </Typography>
      </Box>
      <Button variant='outlined' href='mailto:support@inscopehq.com' sx={buttonStyles}>
        <Typography variant='body2' fontWeight={500}>Email support</Typography>
      </Button>
    </Box>
  );
};

const DisclosureGuidePageComponent = () => {
  const {currentOrg, isAuthenticated} = useSelector((state) => state.session);
  const [topics, setTopics] = useState([]);
  const [filteredTopics, setFilteredTopics] = useState([]);
  const [query, setQuery] = useState('');
  const [skip, setSkip] = useState(true);
  const [sortOrder, setSortOrder] = useState('asc');
  const {data: apiData, isLoading, isError, error} = useGetAccountingTopicsQuery({}, {refetchOnMountOrArgChange: true, skip});

  useEffect(() => {
    setSkip(_.isNil(currentOrg) || _.isEmpty(currentOrg) || !isAuthenticated);
  }, [currentOrg, isAuthenticated]);

  useEffect(() => {
    if (isError) {
      console.error('Error fetching accounting topics', error);
    }
  }, [isError, error]);

  useEffect(() => {
    if (!isLoading && apiData) {
      const sortedUniqueTopics = _.chain(apiData.data)
        .uniqBy('accounting_topic')
        .sortBy('accounting_topic')
        .value();

      setTopics(sortedUniqueTopics);
      setFilteredTopics(sortedUniqueTopics);
    }
  }, [isLoading, apiData]);

  const handleSearch = (query) => {
    let filtered;

    if (!query) {
      filtered = topics;
    } else {
      filtered = topics.filter(topic => topic.accounting_topic.toLowerCase().includes(query.toLowerCase()));
    }

    setFilteredTopics(_.orderBy(filtered, ['accounting_topic'], [sortOrder]));
  };

  const handleSortToggle = () => {
    const newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
    setSortOrder(newSortOrder);
    const sortedTopics = _.orderBy(topics, ['accounting_topic'], [newSortOrder]);
    setFilteredTopics(sortedTopics);
  };

  return (
    <CommonPageWrapper>
      <Stack direction='column' flex={1} justifyContent='center' pb={5}>
        <Stack direction='row' alignItems='center' justifyContent='space-between'>
          <Box display='flex' flexDirection='row' alignItems='center'>
            <SearchInput query={query} setQuery={setQuery} onSearch={handleSearch} />
            <Typography ml={3} variant='body1' sx={{opacity: 0.6}}>{filteredTopics.length} items</Typography>
          </Box>

          <Box display='flex' alignItems='center'>
            <Typography variant='body1' color={BLACK_100}>Sort by:</Typography>
             <IconButton
              onClick={handleSortToggle}
              sx={{
                display: 'flex',
                alignItems: 'center',
                padding: 0,
                '&:hover': {backgroundColor: 'transparent'},
              }}
            >
              <Typography variant='body1' mx={1} color={BLACK_100}>{sortOrder === 'asc' ? 'A - Z' : 'Z - A'}</Typography>
              {sortOrder === 'asc' ? <KeyboardArrowDownIcon sx={{fontSize: 16}} /> : <KeyboardArrowUpIcon sx={{fontSize: 16}} />}
            </IconButton>
          </Box>
        </Stack>

        {query.length > 0 && filteredTopics.length === 0
          ? (
            <EmptySearch />
            )
          : (
            <Grid mt={2} container spacing={3}>
              {isLoading
                ? Array.from({length: 24}).map((_, index) => (
                  <Grid key={index} item xs={6} sm={4} md={3}>
                    <Skeleton
                      variant='rectangular'
                      height={160}
                      animation='wave'
                      sx={{borderRadius: '8px'}}
                    />
                  </Grid>
                ))
                : filteredTopics.map((topic, index) => (
                  <Grid key={index} item xs={6} sm={4} md={3}>
                    <TopicCard topic={topic} />
                  </Grid>
                ))}
            </Grid>
            )
        }
      </Stack>
    </CommonPageWrapper>
  )
}

const mapStateToProps = (state) => {
  return {
    session: state.session,
  }
}

export default connect(mapStateToProps)(withRequiredAuthInfo(DisclosureGuidePage));
