import Box from '@mui/material/Box';
import {APP_HEADER_HEIGHT, WHITE_100} from '../App';
import React from 'react';
import PropTypes from 'prop-types';
import {Stack} from '@mui/material';
import _ from 'lodash';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import {useSelector} from 'react-redux';
import TableHeaderDateCell from './financialStatements/TableHeaderDateCell';

const ReportTableComponent = ({children, visible = true, period, comparePeriod, reportRecord, compareReportRecord, meta}) => {
  const {reviewMode, hasUnpublishedReports} = useSelector((state) => state.reports);
  const {reportingPeriodMap} = useSelector(state => state.periods);

  if (!visible || _.isNil(period) || _.isEmpty(period)) {
    return;
  }

  let _generatedPeriod;
  if (!_.isNil(meta) && _.has(meta, 'generatedPeriod')) {
    _generatedPeriod = reportingPeriodMap[meta.generatedPeriod];
  }

  return (
    <Stack direction={'column'} position={'sticky'} top={APP_HEADER_HEIGHT} alignItems={'flex-start'} width={'100%'} >
      <Box width={'100%'}>
        <Stack direction={'column'} position={'sticky'} top={0} bgcolor={WHITE_100}>
          <Stack direction={'row'} boxShadow={'0px 0px 1px -1px rgba(0,0,0,0.2), 0px 0px 1px 0px rgba(0,0,0,0.14), 0px 0px 3px 0px rgba(0,0,0,0.12)'} borderRadius={'4px 0px'} mt={3}>
            <Box display={'flex'} flex={1} height={'2.25rem'} alignItems={'center'} padding={'0.5rem 2.5rem'} />
            <TableHeaderDateCell period={period} generatedPeriod={_generatedPeriod} reportType={reportRecord?.reportType} isReviewMode={reviewMode} dateCreated={reportRecord?.createdAt} borderBottom={1} minWidth={'15.3125rem'} maxWidth={'15.3125rem'} padding={'0.5rem 2.5rem 0.6rem 1rem'} />
            {!_.isNil(comparePeriod) && !_.isNil(compareReportRecord) && (
              <TableHeaderDateCell period={comparePeriod} generatedPeriod={_generatedPeriod} reportType={reportRecord?.reportType} isReviewMode={false} isUnpublised={hasUnpublishedReports} borderBottom={1} minWidth={'15.3125rem'} maxWidth={'15.3125rem'} padding={'0.5rem 2.5rem 0.6rem 1rem'} />
            )}
          </Stack>
        </Stack>
        <Box paddingBottom={'0.5rem'} boxShadow={'0px 0px 1px -1px rgba(0,0,0,0.2), 0px 0px 1px 0px rgba(0,0,0,0.14), 0px 0px 3px 0px rgba(0,0,0,0.12)'} borderRadius={'0px 4px'}>
          <TableContainer>
            <Table sx={{paddingY: '0.5rem'}}>
              <TableBody>
                {children}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </Stack>
  );
}

ReportTableComponent.propTypes = {
  visible: PropTypes.bool,
  period: PropTypes.shape({
    endDate: PropTypes.string.isRequired,
    periodType: PropTypes.string.isRequired,
  }).isRequired,
  comparePeriod: PropTypes.shape({
    endDate: PropTypes.string.isRequired,
    periodType: PropTypes.string.isRequired,
  }),
  meta: PropTypes.shape({
    generatedPeriod: PropTypes.string,
  }),
  children: PropTypes.node.isRequired,
  reviewMode: PropTypes.bool.isRequired,
  reportRecord: PropTypes.object,
  compareReportRecord: PropTypes.object,
}

export default ReportTableComponent;
