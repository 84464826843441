import React, {useEffect, useState, forwardRef} from 'react'
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import {connect} from 'react-redux';
import Box from '@mui/material/Box';
import {withRequiredAuthInfo} from '@propelauth/react';
import {GREEN_70} from '../App';
import * as api from '../util/api';
import _ from 'lodash';
import {withLogger, LoggerProps} from '../components/LoggingWrapper';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {TableVirtuoso} from 'react-virtuoso';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import {
  FormControl,
  InputLabel, MenuItem,
  Select,
  TableSortLabel,
} from '@mui/material';
import {visuallyHidden} from '@mui/utils';
import CompanyConfigTable from '../components/CompanyConfigTable';
import CommonPageWrapper from '../components/CommonPageWrapper';
import {FSLI_TYPES} from '../constants';
import AppPage from './AppPage';

const _filterRecords = (records, fsliTypeFilter) => {
  if (fsliTypeFilter === 'ALL') {
    return records;
  }

  return _.filter(records, {fsliType: fsliTypeFilter});
}

const _sortRecords = (records, order, orderBy) => {
  return order === 'asc' ? _.sortBy(records, [orderBy]) : _.reverse(_.sortBy(records, [orderBy]))
}

class FSLIPage extends AppPage {
  getPageTitle = () => 'Financial Statement Line Items';
  renderBodyContent = () => <FSLIPageComponent session={this.props.session} logger={this.props.logger} accessToken={this.props.accessToken} />;
}

const FSLIPageComponent = ({accessToken, session, logger}) => {
  const {currentOrg} = session;
  const [fslis, setFSLIs] = useState([]);
  const [filteredRecords, setFilteredRecords] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [hasError, setHasError] = useState(false);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('fsliType');
  const [fsliTypeFilter, setFSLITypeFilter] = useState('ALL');

  useEffect(() => {
    if (_.isNil(currentOrg) || _.isNil(currentOrg.orgId) || _.isEmpty(currentOrg.orgId)) {
      return setFSLIs([]);
    }
    setLoading(true);
    setErrorMessage('');
    setHasError(false);
    api.getFSLIs(accessToken, currentOrg.orgId).then(_response => {
      logger.debug('FSLIs loaded', {fslis: _response});
      const _sortedFSLIs = _sortRecords(_response.fslis, order, orderBy);
      setFSLIs(_sortedFSLIs);
      setFilteredRecords(_filterRecords(_sortedFSLIs, fsliTypeFilter));
    }).catch(_error => {
      logger.error('Error loading departments', _error);
      setErrorMessage('There was an error processing your request. Please try again later.');
      setHasError(true);
    }).finally(() => {
      setLoading(false);
    });

    return setFSLIs([]);
  }, [currentOrg]);

  useEffect(() => {
    setFilteredRecords(_sortRecords(filteredRecords, order, orderBy));
  }, [order, orderBy]);

  useEffect(() => {
    setFilteredRecords(_sortRecords(_filterRecords(fslis, fsliTypeFilter), order, orderBy));
  }, [fsliTypeFilter]);

  const VTCScroller = forwardRef((props, ref) => (
    <TableContainer {...props} ref={ref} />
  ));
  VTCScroller.displayName = 'Scroller';
  const VTCTableBody = forwardRef((props, ref) => <TableBody {...props} ref={ref} />);
  VTCTableBody.displayName = 'TableBody';
  const VTCTableRow = ({item: _item, ...props}) => <TableRow {...props} />;
  VTCTableRow.propTypes = {
    item: PropTypes.any.isRequired,
  };

  const VirtuosoTableComponents = {
    displayName: 'VirtuosoTableComponents',
    Scroller: VTCScroller,
    Table: (props) => (
      <Table {...props} sx={{borderCollapse: 'separate', tableLayout: 'fixed', minHeight: 'auto', height: 'auto'}} />
    ),
    TableHead,
    TableRow: VTCTableRow,
    TableBody: VTCTableBody,
  };

  const _headers = [
    {
      id: 'name',
      label: 'FSLI',
    },
    {
      id: 'fsliType',
      label: 'Report',
    },
  ]

  const handleRequestSort = (property) => () => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  }

  const handleFilterChange = event => {
    setFSLITypeFilter(event.target.value);
  }

  const fixedHeaderContent = () => {
    return (
      <TableRow>
        {_.map(_headers, _header =>
          <TableCell
            key={_header.id}
            sortDirection={orderBy === _header.id ? order : false}
            variant={'head'}
            align={'left'}
            datatestid={`tableHeader-${_header.id}`}
            sx={{
              borderBottom: 1,
            }}
          >
            <TableSortLabel
                active={orderBy === _header.id}
                direction={orderBy === _header.id ? order : 'asc'}
                onClick={handleRequestSort(_header.id)}>
              <Typography variant={'body2'}>{_header.label}</Typography>
              {orderBy === _header.id
                ? (
                      <Box component={'span'} sx={visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </Box>
                  )
                : null}
            </TableSortLabel>
          </TableCell>,
        )}
      </TableRow>
    );
  }

  const rowContent = (_index, row) => {
    return (
      <React.Fragment key={_index}>
        <TableCell>
          <Typography>{row.name}</Typography>
        </TableCell>
        <TableCell>
          <Typography>{FSLI_TYPES[row.fsliType]}</Typography>
        </TableCell>
      </React.Fragment>
    );
  }

  return (
    <CommonPageWrapper>
      <CompanyConfigTable
        hasError={!_.isNil(errorMessage) && !_.isEmpty(errorMessage)}
        errorMessage={"There was an error loading your data. We're on it!"}
        loading={loading}
        screen={'FSLI'}
        success={!hasError}
        hasData={!_.isEmpty(fslis)}>
        <Box width={'100%'} display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'flex-end'} paddingTop={'1.5rem'} paddingRight={'1.5rem'} sx={{backgroundColor: GREEN_70}}>
          <Typography>Show for report</Typography>
          <FormControl sx={{marginLeft: '12px', width: '200px'}}>
            <InputLabel id={'fsliType-filter-label'}>Report Type</InputLabel>
            <Select
              labelId={'fsliType-filter-label'}
              id={'fsliType-select'}
              value={fsliTypeFilter}
              label={'Report Type'}
              onChange={handleFilterChange}
            >
              <MenuItem value={'ALL'}>All</MenuItem>
              {
                _.map(FSLI_TYPES, (value, key) =>
                  <MenuItem key={key} value={key}>{value}</MenuItem>,
                )
              }
            </Select>
          </FormControl>
        </Box>
        <TableVirtuoso
          data={filteredRecords}
          components={VirtuosoTableComponents}
          fixedHeaderContent={fixedHeaderContent}
          itemContent={rowContent}
        />
      </CompanyConfigTable>
    </CommonPageWrapper>
  )
}

FSLIPageComponent.propTypes = {
  accessToken: PropTypes.string.isRequired,
  session: PropTypes.shape({
    currentOrg: PropTypes.shape({
      orgId: PropTypes.string,
    }),
  }),
  logger: LoggerProps,
}

const mapStateToProps = (state) => {
  return {
    session: state.session,
  }
}

export default connect(mapStateToProps)(withRequiredAuthInfo(withLogger(FSLIPage)));
