import React from 'react';
import log from 'loglevel';
import PropTypes from 'prop-types';

export const withLogger = WrappedComponent => {
  log.setLevel(process.env.LOG_LEVEL || 'debug');

  return function LoggerComponent (props) {
    return <WrappedComponent {...props} logger={log}/>;
  }
}

export const LoggerProps = PropTypes.shape({
  debug: PropTypes.func,
  info: PropTypes.func,
  warn: PropTypes.func,
  error: PropTypes.func,
});
