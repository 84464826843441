import React, {useState} from 'react';
import _ from 'lodash';
import {APP_HEADER_HEIGHT, FOOTER_HEIGHT, GREY_80} from '../App';
import TableRow from '@mui/material/TableRow';
import TableCell from './TableCell';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import IconButton from '@mui/material/IconButton';
import TableSortLabel from '@mui/material/TableSortLabel';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {visuallyHidden} from '@mui/utils';
import TableBody from '@mui/material/TableBody';
import PropTypes from 'prop-types';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {useSelector} from 'react-redux';
import TableHeaderDateCell from './financialStatements/TableHeaderDateCell';

const DetailTable = ({order, orderBy, handleRequestSort, renderTableBody, tableHeaders, period, comparePeriod, reportType, generatedPeriod, hasHeader = true, collapsable = false, expandAll}) => {
  const [open, isOpen] = useState(false);
  const {reviewMode, hasUnpublishedReports} = useSelector((state) => state.reports);

  return (
    <TableContainer component={Paper} sx={{maxHeight: `calc(100vh - ${APP_HEADER_HEIGHT} - ${FOOTER_HEIGHT} - (2 * 1.75rem) - 4.25rem - (2 * 1.25rem))`}}>
      <Table stickyHeader>
        {hasHeader && (
          <TableHead>
            <TableRow>
              {
                _.map(tableHeaders, _header => {
                  const _sx = {
                    borderBottom: 1,
                    borderColor: GREY_80,
                    paddingLeft: collapsable ? 0 : '2.5rem',
                    verticalAlign: 'bottom',
                  };
                  if (_.has(_header, 'width')) {
                    _sx.width = _header.width;
                  }
                  return (
                    <TableCell
                      key={_header.id}
                      sx={_sx}
                      variant={'head'}
                      align={'left'}
                      sortDirection={orderBy === _header.id ? order : false}
                    >
                      {collapsable && (
                        <IconButton size={'small'} onClick={() => {
                          isOpen(!open);
                          expandAll();
                        }}>
                          {open && <ExpandMoreIcon />}
                          {!open && <ChevronRightIcon />}
                        </IconButton>
                      )}
                      <TableSortLabel
                        active={orderBy === _header.id}
                        direction={orderBy === _header.id ? order : 'asc'}
                        onClick={handleRequestSort(_header.id)}>
                        <Typography variant={'body2'} fontWeight={'600'}>
                          {_header.label}
                        </Typography>
                        {orderBy === _header.id
                          ? (
                            <Box component={'span'} sx={visuallyHidden}>
                              {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                            )
                          : null}
                      </TableSortLabel>
                    </TableCell>
                  )
                })
              }
              <TableCell
                align={'right'}
                sx={{borderBottom: 1, width: '15rem'}}
                variant={'head'}
                sortDirection={orderBy === 'periodEndDate' ? order : false}
              >
                <TableSortLabel
                  active={orderBy === 'amount'}
                  direction={orderBy === 'amount' ? order : 'asc'}
                  onClick={handleRequestSort('amount')}>
                  <TableHeaderDateCell period={period} generatedPeriod={generatedPeriod} reportType={reportType} isReviewMode={reviewMode && _.isNil(comparePeriod)} minWidth={'max-content'} />
                  {orderBy === 'amount'
                    ? (
                      <Box component={'span'} sx={visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </Box>
                      )
                    : null}
                </TableSortLabel>
              </TableCell>
              {((!reviewMode && !_.isNil(comparePeriod) && !_.isEmpty(comparePeriod.endDate)) || (reviewMode && hasUnpublishedReports)) && (
                <TableCell
                  align={'right'}
                  sx={{borderBottom: 1, width: '15rem'}}
                  variant={'head'}
                  sortDirection={orderBy === 'compareTotal' ? order : false}
                >
                  <TableSortLabel
                    active={orderBy === 'compareTotal'}
                    direction={orderBy === 'compareTotal' ? order : 'asc'}
                    onClick={handleRequestSort('compareTotal')}>
                    <TableHeaderDateCell period={comparePeriod} generatedPeriod={generatedPeriod} reportType={reportType} isReviewMode={reviewMode && _.isNil(comparePeriod)} minWidth={'max-content'} />
                    {orderBy === 'compareTotal'
                      ? (
                        <Box component={'span'} sx={visuallyHidden}>
                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </Box>
                        )
                      : null}
                  </TableSortLabel>
                </TableCell>
              )}
            </TableRow>
          </TableHead>
        )}
        <TableBody>
          {renderTableBody()}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

DetailTable.propTypes = {
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  handleRequestSort: PropTypes.func.isRequired,
  renderTableBody: PropTypes.func.isRequired,
  tableHeaders: PropTypes.array.isRequired,
  generatedPeriod: PropTypes.shape({
    endDate: PropTypes.string.isRequired,
    periodType: PropTypes.string.isRequired,
  }),
  period: PropTypes.shape({
    endDate: PropTypes.string.isRequired,
    periodType: PropTypes.string.isRequired,
  }).isRequired,
  comparePeriod: PropTypes.shape({
    endDate: PropTypes.string.isRequired,
    periodType: PropTypes.string.isRequired,
  }),
  reportType: PropTypes.string,
  hasHeader: PropTypes.bool,
  collapsable: PropTypes.bool,
  expandAll: PropTypes.func,
};

export default DetailTable;
