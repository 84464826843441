import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import _ from 'lodash';
import {FOOTER_HEIGHT, WHITE_100} from '../App';
import MenuBarV2 from '../components/MenuBarV2';
import AppBarV2 from '../components/AppBarV2';
import {Outlet, useLocation} from 'react-router-dom';
import {withLDConsumer} from 'launchdarkly-react-client-sdk';

const ALPHA_ENV_FOOTER = '#2A3C82';
const DEV_ENV_FOOTER = '#823C2A';

const DefaultLayout = () => {
  const {pathname} = useLocation();

  const renderEnvironmentFooter = () => {
    const _baseProps = {
      height: FOOTER_HEIGHT,
      color: WHITE_100,
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    };
    switch (process.env.REACT_APP_ENVIRONMENT) {
      case 'production':
        return null;
      case 'staging':
        return (
          <Box bgcolor={ALPHA_ENV_FOOTER} {..._baseProps} sx={{textAlign: 'center', display: 'flex', flexDirection: 'row', flex: 1, justifyContent: 'center', alignItems: 'center'}} elevation={3} zIndex={999}>
            <Typography>This is the <strong>STAGING</strong> environment.</Typography>
          </Box>
        )
      default:
        return (
          <Box bgcolor={DEV_ENV_FOOTER} {..._baseProps} zIndex={999}>
            <Typography>This is a <strong>{_.toUpper(process.env.REACT_APP_ENVIRONMENT) || 'DEV'}</strong> environment.</Typography>
          </Box>
        )
    }
  }

  return (
    <Stack direction={'column'} height={'100vh'} width={'100vw'}>
      <Stack direction={'row'} height={'100%'} width={'100%'} maxHeight={`calc(100% - ${FOOTER_HEIGHT})`}>
        <AppBarV2 />
        {!pathname.startsWith('/disclosure-library') &&
          <MenuBarV2 />
        }
        <Outlet />
      </Stack>
      {renderEnvironmentFooter()}
    </Stack>
  )
}

export default withLDConsumer()(DefaultLayout);
