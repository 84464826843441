import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';

const CommonPageWrapper = ({children, ...props}) => {
  return (
    <Box display={'flex'} flex={1} py={'1.5rem'} justifyContent={'center'} alignItems={'flex-start'} {...props}>
      {children}
    </Box>
  );
}

CommonPageWrapper.propTypes = {
  children: PropTypes.any,
};

export default CommonPageWrapper;
