import {useCallback, useEffect} from 'react';

export function removeCommentHighlight (editor, highlightId) {
  let result = false;

  editor.state.doc.descendants((node, pos) => {
    if (node.isText || node.isInline) {
      node.marks.forEach((mark) => {
        const dataId = mark.attrs.highlightId;

        if (dataId === highlightId) {
          const from = pos;
          const to = pos + node.nodeSize;

          // Create and dispatch a transaction to remove the mark
          const transaction = editor.state.tr.removeMark(from, to, mark.type);
          editor.view.dispatch(transaction);
          result = true;
        }
      });
    }

    // handle cases where the Node itself is marked (linkedText)
    if (node.attrs.highlightId === highlightId) {
      const from = pos;
      const to = pos + node.nodeSize;

      const transaction = editor.state.tr.removeMark(from, to, editor.schema.marks.commentHighlight);
      editor.view.dispatch(transaction);
      result = true;
    }
  });

  return result;
}

// Reapply a comment highlight mark if a previously resolved comment is re-opened
export function reapplyCommentHighlight (editor, highlightId, metadata) {
  let result = false;
  let {from, to} = metadata;

  // Check if the 'from' position is still valid
  if (from < 0 || from > editor.state.doc.content.size) {
    console.warn('Invalid highlight start position');
    return false;
  }

  // Find the end of the current content starting from the 'from' position
  const $from = editor.state.doc.resolve(from);
  const $to = editor.state.doc.resolve(
    Math.min(to, $from.node().nodeSize + $from.start()),
  );

  // Adjust 'to' to the end of the current content
  to = $to.pos;

  // Create a new mark with the highlightId
  const mark = editor.schema.marks.commentHighlight.create({highlightId, state: 'complete'});

  // Create and dispatch a transaction to add the mark
  const transaction = editor.state.tr.addMark(from, to, mark);
  editor.view.dispatch(transaction);
  result = true;

  return result;
}

// Get the mark and node of a comment highlight from `data-highlight-id`
export function getCommentHighlight (editor, highlightId) {
  let result = null;

  // Iterate over each node in the document
  editor.state.doc.descendants((node, pos) => {
    // Check if the node itself has the mark
    if (node.isText || node.isInline) {
      node.marks.forEach((mark) => {
        const dataId = mark.attrs.highlightId;

        if (dataId === highlightId) {
          result = {mark, node, pos};
        }
      });
    }

    // Additionally, handle cases where the node itself is marked (linkedText)
    if (node.attrs.highlightId === highlightId) {
      const mark = node.marks.find((m) => m.type.name === 'commentHighlight');
      if (mark) {
        result = {mark, node, pos};
      }
    }
  });

  return result;
}

export function getCommentHighlightContent (highlightId) {
  const editorContainer = document.querySelector('.tiptap');
  const elements = editorContainer.querySelectorAll(`[data-highlight-id="${highlightId}"]`);

  for (const elem of elements) {
    const linkedTextElem = elem.querySelector('.linked-text');

    if (linkedTextElem) {
      const content = linkedTextElem.innerHTML
        .replace(/&nbsp;|&ensp;|&emsp;|&thinsp;|&hairsp;/g, ' ')
        .replace(/[\u200B-\u200D\uFEFF]/g, '')
        .trim();
      if (content.length > 0) {
        return linkedTextElem.innerHTML;
      }
    }

    const content = elem.innerHTML
      .replace(/&nbsp;|&ensp;|&emsp;|&thinsp;|&hairsp;/g, ' ')
      .replace(/[\u200B-\u200D\uFEFF]/g, '')
      .trim();
    if (content.length > 0) {
      return elem.innerHTML;
    }
  }

  return null;
}

export const HIGHLIGHT_EVENT_NAME = 'commentHighlight';

// Trigger highlight active
export function highlightEvent (highlightId, isClick = false) {
  const event = new CustomEvent(HIGHLIGHT_EVENT_NAME, {
    detail: {highlightId, isClick},
  });

  document.documentElement.dispatchEvent(event);
}

export function useHighlightEvent () {
  return useCallback((highlightId) => {
    const event = new CustomEvent(HIGHLIGHT_EVENT_NAME, {
      detail: {highlightId},
    });

    document.documentElement.dispatchEvent(event);
  }, []);
}

export function useHighlightEventListener (callback) {
  useEffect(() => {
    function handler (event) {
      callback(event.detail.highlightId, event.detail.isClick);
    }

    document.documentElement.addEventListener(
      HIGHLIGHT_EVENT_NAME,
      handler,
    );

    return () => {
      document.documentElement.removeEventListener(
        HIGHLIGHT_EVENT_NAME,
        handler,
      );
    };
  }, [callback]);
}

export function getUserIdsFromThreads (threads) {
  const userIds = new Set();

  const extractMentionIds = (content) => {
    if (Array.isArray(content)) {
      content.forEach(item => {
        if (item.type === 'mention' && item.id) {
          userIds.add(item.id);
        }
        if (item.children) {
          extractMentionIds(item.children);
        }
      });
    }
  }

  for (const thread of threads) {
    if (thread.comments && Array.isArray(thread.comments)) {
      for (const comment of thread.comments) {
        if (comment.userId) {
          userIds.add(comment.userId);
        }

        if (comment.body && comment.body.content) {
          extractMentionIds(comment.body.content);
        }
      }
    }
  }

  return Array.from(userIds);
}

export function threadMatchesFilters (thread, filters) {
  const {status, userId} = filters;

  const isResolved = thread.resolved || thread.metadata?.resolved;
  if (status === 'unresolved' && isResolved) return false;
  if (status === 'resolved' && !isResolved) return false;

  if (!userId) return true;

  const isAuthor = thread.comments.some(comment => comment.userId === userId);
  if (isAuthor) return true;

  const isMentioned = thread.comments.some(comment => {
    if (comment.body && comment.body.content) {
      return comment.body.content.some(content => {
        if (Array.isArray(content.children)) {
          return content.children.some(child =>
            child.type === 'mention' && child.id === userId,
          );
        }
        return false;
      });
    }
    return false;
  });

  return isMentioned;
}
