import {useEffect, useState} from 'react';
import spacetime from 'spacetime';
import PropTypes from 'prop-types';

const TimeAgo = ({timestamp}) => {
  const [currentTime, setCurrentTime] = useState(spacetime.now());

  useEffect(() => {
    const _timer = setInterval(() => {
      setCurrentTime(spacetime.now());
    }, 1000); // Update every second

    // Clear interval on component unmount
    return () => {
      clearInterval(_timer);
    };
  }, []);

  return currentTime.since(timestamp).rounded;
}

TimeAgo.propTypes = {
  timestamp: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
};

export default TimeAgo;
