import {createSlice} from '@reduxjs/toolkit';

const INITIAL_STATE = {
  document: {},
}

export const documentsSlice = createSlice({
  name: 'documents',
  initialState: INITIAL_STATE,
  reducers: {
    setDocument: (state, action) => {
      state.document = action.payload.document;
    },
    reset: () => INITIAL_STATE,
  },
})

export const {reset, setDocument} = documentsSlice.actions;
export default documentsSlice.reducer;
