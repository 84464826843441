import {createApi} from '@reduxjs/toolkit/query/react';
import _ from 'lodash';
import axios from 'axios';
import store from '../store';

const axiosBaseQuery =
  ({baseUrl} = {baseUrl: ''}) =>
    async ({url, method = 'GET', data, params, headers}) => {
      const {session} = store.getState();
      const _headers = {
        'x-inscope-org': session.currentOrg.orgId,
        ...headers,
      }
      if (!_.isNil(session.accessToken)) {
        _headers.Authorization = `Bearer ${session.accessToken}`;
      }
      const result = await axios({
        url: `${baseUrl}${url}`,
        method,
        data,
        params,
        headers: _headers,
      })
      return {data: result.data}
    }

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_API_URL,
  }),
  endpoints: builder => ({
    getCurrentUser: builder.query({
      query: () => ({
        url: '/app/whoAmI',
      }),
    }),
    getAccounts: builder.query({
      query: () => ({
        url: '/app/accounts',
      }),
      transformResponse: responseData => {
        if (_.isNil(responseData) || _.isNil(responseData.accounts)) {
          return [];
        }
        return responseData.accounts;
      },
    }),
    getAccountMappings: builder.query({
      query: () => ({
        url: '/app/accountMappings',
      }),
      transformResponse: responseData => {
        if (_.isNil(responseData) || _.isNil(responseData.accountMappings)) {
          return [];
        }
        return responseData.accountMappings;
      },
    }),
    getDepartments: builder.query({
      query: () => ({url: '/app/departments'}),
      transformResponse: responseData => {
        if (_.isNil(responseData) || _.isNil(responseData.departments)) {
          return [];
        }
        return responseData.departments;
      },
    }),
    getFSLI: builder.query({
      query: () => ({url: '/app/fslis'}),
      transformResponse: responseData => {
        if (_.isNil(responseData) || _.isNil(responseData.fslis)) {
          return [];
        }
        return responseData.fslis;
      },
    }),
    getOpexMappings: builder.query({
      query: () => ({url: '/app/opexMappings'}),
      transformResponse: responseData => {
        if (_.isNil(responseData) || _.isNil(responseData.opexMappings)) {
          return [];
        }
        return responseData.opexMappings;
      },
    }),
    getOrg: builder.query({
      query: () => ({url: '/app/org'}),
      transformResponse: responseData => {
        return _.get(responseData, 'org', {});
      },
    }),
    getPresentationItems: builder.query({
      query: () => ({url: '/app/presentationItems'}),
      transformResponse: responseData => {
        if (_.isNil(responseData) || _.isNil(responseData.items)) {
          return [];
        }
        return responseData.items;
      },
    }),
    getReportDetails: builder.query({
      query: ({reportId, fsliId, pliId}) => ({
        url: `/app/report/${reportId}/${!_.isNil(pliId) ? pliId : fsliId}/details`,
      }),
      transformResponse: responseData => {
        if (_.isNil(responseData) || _.isNil(responseData.details)) {
          return [];
        }
        return _.filter(responseData.details, _record => Math.abs(_record.total) >= 1);
      },
    }),
    getReportDetailsV2: builder.query({
      async queryFn (args, queryApi, extraOptions, baseQuery) {
        let _response = [];
        if (!_.isNil(args.pliIds)) {
          const _promiseResponse = await Promise.all(_.map(args.pliIds, async _id => {
            return (await baseQuery({
              url: `/app/report/details/${args.reportType}/${_id}`,
              params: {period: args.period, comparePeriod: args.comparePeriod},
            })).data;
          }));
          const _parsedResult = _.concat(..._.map(_promiseResponse, _o => _o.details));
          const _sortedResults = _.sortBy(_parsedResult, ['label']);
          _response = {
            details: _sortedResults,
          }
        } else {
          _response = (await baseQuery({
            url: `/app/report/details/${args.reportType}/${args.pliId}`,
            params: {period: args.period, comparePeriod: args.comparePeriod, reviewMode: args.reviewMode, currentFileId: args.currentFileId, compareFileId: args.compareFileId},
          })).data;
        }
        if (_.isNil(_response) || _.isNil(_response.details)) {
          return [];
        }
        return {data: _.filter(_response.details, _record => Math.abs(_record.amount) >= 1 || Math.abs(_record.compareAmount) >= 1)};
      },
    }),
    getReportingPeriods: builder.query({
      query: () => ({url: '/app/reportingPeriods'}),
      transformResponse: responseData => {
        if (_.isNil(responseData) || _.isNil(responseData.periods)) {
          return [];
        }
        return _.reverse(_.sortBy(responseData.periods, ['endDate']))
      },
    }),
    getReportReconciliation: builder.query({
      query: ({gaapReportId, nonGaapReportId, period}) => ({
        url: '/app/report/reconciliation',
        params: {
          gaapReportId,
          nonGaapReportId,
          period,
        },
      }),
      transformResponse: responseData => {
        if (_.isNil(responseData) || _.isNil(responseData.details)) {
          return [];
        }
        return _.filter(responseData.details, _o => _.total > 0);
      },
    }),
    getReportsList: builder.query({
      query: ({period, comparePeriod, reviewMode}) => ({
        url: '/app/reports',
        params: {period, comparePeriod, reviewMode},
      }),
    }),
    publishReports: builder.mutation({
      query: () => ({
        url: '/app/reports/publish',
        method: 'POST',
      }),
      transformResponse: responseData => {
        return _.get(responseData, 'publishedReports', []);
      },
    }),
    getWorkspaceSignedUrl: builder.query({
      query: ({filename}) => ({
        url: `/app/workspace/${filename}`,
        method: 'GET',
      }),
    }),
    createWorkspaceSignedUrl: builder.mutation({
      query: (filename) => ({
        url: `/app/workspace/${filename}`,
        method: 'POST',
      }),
    }),
  }),
});

export const {
  endpoints,
  useGetCurrentUserQuery,
  useGetAccountMappingsQuery,
  useGetAccountsQuery,
  useGetDepartmentsQuery,
  useGetFSLIQuery,
  useGetOpexMappingsQuery,
  useGetOrgQuery,
  useGetPresentationItemsQuery,
  useGetReportDetailsQuery,
  useGetReportDetailsV2Query,
  useGetReportDetailsWithChildrenV2Query,
  useGetReportingPeriodsQuery,
  useGetReportReconciliationQuery,
  useGetReportsListQuery,
  usePublishReportsMutation,
  useCreateWorkspaceSignedUrlMutation,
  useGetWorkspaceSignedUrlQuery,
} = apiSlice;
