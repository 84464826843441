import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React from 'react';
import PropTypes from 'prop-types';
import {dotPulse} from 'ldrs';

dotPulse.register();

const DEFAULT_LOADING_PROPS = {color: '#31C67D', size: '43', speed: '1.3'};
const DEFAULT_TYPOGRAPHY_PROPS = {mt: 3, fontSize: '1.25rem'};
const DEFAULT_CONTAINER_PROPS = {direction: 'column', flex: 1, height: '100%', justifyContent: 'center', alignItems: 'center'};

const LoadingComponent = ({loading = true, message, loadingProps = {}, typographyProps = {}, containerProps = {}}) => {
  const _loadingProps = {
    ...DEFAULT_LOADING_PROPS,
    ...loadingProps,
  }
  const _typographyProps = {
    ...DEFAULT_TYPOGRAPHY_PROPS,
    ...typographyProps,
  }
  const _containerProps = {
    ...DEFAULT_CONTAINER_PROPS,
    ...containerProps,
  }
  if (loading) {
    return (
      <Stack {..._containerProps}>
        <l-dot-pulse {..._loadingProps}></l-dot-pulse>
        {message && (
          <Typography {..._typographyProps}>{message}</Typography>
        )}
      </Stack>
    );
  }
}

LoadingComponent.propTypes = {
  loading: PropTypes.bool.isRequired,
  message: PropTypes.string,
  color: PropTypes.string,
  loadingProps: PropTypes.shape({
    color: PropTypes.string,
    size: PropTypes.string,
    speed: PropTypes.string,
  }),
  typographyProps: PropTypes.object,
  containerProps: PropTypes.object,
}

export default LoadingComponent;
