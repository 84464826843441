import _ from 'lodash';
import axios from 'axios';
import spacetime from 'spacetime';
import numeral from 'numeral';
import {TOTAL_FSLIS} from '../constants';
import Decimal from 'decimal.js-light';
import {datadogRum} from '@datadog/browser-rum';
import {datadogLogs} from '@datadog/browser-logs'
import Intercom from '@intercom/messenger-js-sdk';

export const triggerDownload = async (getFile, filename) => {
  const _response = await getFile();
  if (_.has(_response, 'signedUrl')) {
    const _file = await axios.get(_response.signedUrl, {
      headers: {
        'Content-Type': 'application/octet-stream',
      },
      responseType: 'blob',
    });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(_file.data);
    link.download = filename;
    link.click();
    link.remove();
  } else {
    const _error = new Error('MissingURL');
    _error.message = 'URL not found to download';
    throw _error
  }
}

export const getPeriodEndDate = (period, generatedPeriod) => {
  if (period.isClosed || _.isNil(generatedPeriod)) {
    return spacetime(period.endDate).endOf('month').format('{month-short} {date}, {year}');
  }

  let _toDate = '';
  switch (period.periodType) {
    case 'year':
      _toDate = ' (YTD)';
      break;
    case 'quarter':
      _toDate = ' (QTD)';
      break;
    case 'month':
      _toDate = ' (MTD)';
      break;
  }

  return `${spacetime(generatedPeriod.endDate).endOf('month').format('{month-short} {date}, {year}')}${_toDate}`;
}

export const getPeriodTimeframe = (period, generatedPeriod) => {
  if (period.isClosed || _.isNil(generatedPeriod)) {
    switch (period.periodType) {
      case 'year':
        return '12 months ended';
      case 'quarter':
        return '3 months ended';
      default:
        return '1 month ended';
    }
  }
  const _periodStart = spacetime(period.startdate);
  const _generatedEnd = spacetime(generatedPeriod.endDate);
  const _diff = _periodStart.diff(_generatedEnd, 'month');
  return `${_diff + 1} ${_diff === 0 ? 'month' : 'months'} ended`;
}

export const isTotalRow = fsli => {
  return _.some(TOTAL_FSLIS, _fsli => _.startsWith(fsli, _fsli));
}

export const formatAmount = (_row, _idx, firstDollar, indexedReportData) => {
  let retval = ''
  let compareVal = ''
  let start = '$'
  if (_.isNil(_row.amount) || (_.has(_row, 'omit_amount') && _row.omit_amount === true)) {
    return {styling: {}, retVal: '', compareVal: ''}
  }

  if (_row.amount === 0) {
    retval = '-';
  } else if (_row.amount === '-') {
    retval = _row.amount;
  } else if (_row.amount < 0) {
    retval = numeral(_row.amount).format('(0,0)')
  } else {
    retval = ` ${numeral(_row.amount).format('0,0')} `
  }
  if (_row.compareAmount === 0) {
    compareVal = '-';
  } else if (_row.compareAmount === '-') {
    compareVal = _row.compareAmount;
  } else if (_row.compareAmount < 0) {
    compareVal = numeral(_row.compareAmount || 0).format('(0,0)')
  } else {
    compareVal = ` ${numeral(_row.compareAmount || 0).format('0,0')} `
  }

  const rowindex = _idx
  // ENG-202 - "Total Liabilities" should not include a $
  const hackList = ['Total Assets', 'Accounts Payable']
  if (rowindex === firstDollar ||
    rowindex === indexedReportData.length - 1 ||
    hackList.includes(_row.fsli)) {
    start = '$'
  } else {
    start = ' '
  }

  retval = `${start} ${retval.padStart(12, ' ')} `
  compareVal = `${start} ${compareVal.padStart(12, ' ')} `
  let styling = { }
  // ENG-202 - "Total Liabilities" should only have a single line below
  if (isTotalRow(_row.fsli)) {
    styling = {borderTop: '1px solid black', borderBottom: ''}
  }
  const uPrefixes = ['Total Liabilities'];
  if (_.includes(uPrefixes, _row.fsli)) {
    styling = {...styling, borderBottom: '1px solid black'}
  }
  const duPrefixes = ['Total Assets', 'Net Profit', 'Net Profit (Loss)', 'Cash, cash equivalents and restricted cash at end of the period', "Total Liabilities and Stockholders' Equity"]
  if (duPrefixes.some(prefix => _row.fsli.startsWith(prefix))) {
    styling = {...styling, borderBottom: '3px double black'}
  }
  return {styling, retval, compareVal};
}

export const formatDate = (date) => {
  const d = new Date(date);
  const month = String(d.getMonth() + 1).padStart(2, '0');
  const day = String(d.getDate()).padStart(2, '0');
  const year = d.getFullYear();

  return `${month}/${day}/${year}`;
}

export const enableHover = (reportType, rowData, flag = true) => {
  return flag && _.includes(['BALANCE_SHEET', 'INCOME_STATEMENT_GAAP', 'INCOME_STATEMENT_NON_GAAP'], reportType) && !isTotalRow(rowData.fsli) && !_.isNil(rowData.amount) && rowData.amount !== 0;
}

export const renderAmount = (amount, invertDisplayAmount = false) => {
  if (_.isNil(amount)) {
    return '-';
  } else if (_.isObject(amount)) {
    return amount;
  } else if (!_.isNumber(amount)) {
    return invertDisplayAmount ? `-${amount}` : amount;
  }
  const _amount = numeral(invertDisplayAmount ? new Decimal(amount).times(-1).todp(0).toNumber() : new Decimal(amount).todp(0).toNumber()).format('(0,0)');
  if (_amount === '0') {
    return '-';
  } else {
    return _amount;
  }
}

export const sortRecords = (records, order, orderBy, secondaryOrderBy) => {
  if (!_.isNil(secondaryOrderBy)) {
    return _.orderBy(records, [orderBy, secondaryOrderBy], [order, 'asc']);
  }
  return _.orderBy(records, [orderBy], [order]);
}

export const isTruthy = value => {
  return (_.isBoolean(value) && value) || _.toLower(value) === 'true' || _.toLower(value) === 'yes' || _.toLower(value) === 't' || (_.isNumber(value) && value === 1);
}

export const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));

export const truncate = (str, length = 36) => {
  return (str && str.length > length) ? str.slice(0, length - 1) + '...' : str;
};

export const generateSHA256Hash = async (input) => {
  const encoder = new TextEncoder();
  const data = encoder.encode(input);
  const hashBuffer = await crypto.subtle.digest('SHA-256', data);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  return hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
};

export const identify = async (ldClient, user, org, logger, userIntercomHash = '') => {
  if (_.isEmpty(user) || _.isEmpty(org)) {
    return;
  }
  ldClient.identify({kind: 'multi', user: {key: user.userId, name: `${user.firstName} ${user.lastName}`, email: user.email}, organization: {key: org.orgId, name: org.orgName}}).then(() => logger.debug('LD - User identified (initial)')).catch(_error => logger.error('Error identifying user (initial)', {error: _error, user, org}));
  const _uid = await generateSHA256Hash(`${user.userId}:${org.orgId}`);
  datadogRum.setUser({
    id: _uid,
    name: `${user.firstName} ${user.lastName} (${org.orgName})`,
    email: user.email,
    org: org.orgId,
    companyName: org.orgName,
  });

  if (userIntercomHash) {
    Intercom({
      app_id: process.env.REACT_APP_INTERCOM_APP_ID,
      user_id: user.userId,
      user_hash: userIntercomHash,
      name: `${user.firstName} ${user.lastName}`,
      email: user.email,
      created_at: user.createdAt,
      company: {
        company_id: org.orgId,
        name: org.orgName,
      },
    });
  }
}

export const trackEvent = (name, properties, duration) => {
  const _properties = properties;
  if (!_.isNil(duration)) {
    _properties.duration = duration;
  }
  datadogLogs.logger.info(name, {properties: _properties});
  datadogRum.addAction(name, _properties);
}

export const getDirectoryPath = (filePath) => {
  if (!filePath) return 'Not found';
  const lastSlashIndex = Math.max(filePath.lastIndexOf('/'), filePath.lastIndexOf('\\'));
  if (lastSlashIndex === -1) return filePath;
  return filePath.substring(0, lastSlashIndex);
}
