import React from 'react';
import AppPage from './AppPage';
import CommonPageWrapper from '../components/CommonPageWrapper';
import Typography from '@mui/material/Typography';
import {Stack} from '@mui/material';

class HelpPage extends AppPage {
  getPageTitle = () => 'Help';
  renderBodyContent = () => <HelpPageComponent />;
}

const HelpPageComponent = () => {
  return (
    <CommonPageWrapper>
      <Stack direction={'column'} spacing={2}>
        <Typography align={'center'} variant={'body2'}>Oh no! Looks like you need help.</Typography>
        <Typography align={'center'}>Please send us a note to <a href="mailto:support@inscopehq.com">support@inscopehq.com</a> and we&apos;ll get back to you soon.</Typography>
      </Stack>
    </CommonPageWrapper>
  )
}

export default HelpPage;
