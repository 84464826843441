import {createSlice} from '@reduxjs/toolkit';
import _ from 'lodash';

const INITIAL_STATE = {
  reportingPeriods: [],
  reportingPeriodMap: {},
  loading: true,
  selectedPeriod: '',
  selectedComparePeriod: '',
}

export const periodsSlice = createSlice({
  name: 'periods',
  initialState: INITIAL_STATE,
  reducers: {
    setPeriods: (state, action) => {
      state.reportingPeriods = action.payload.reportingPeriods;
      state.reportingPeriodMap = _.keyBy(action.payload.reportingPeriods, 'period');
      state.loading = false;
    },
    setSelectedPeriod: (state, action) => {
      state.selectedPeriod = action.payload.period;
    },
    setSelectedComparePeriod: (state, action) => {
      state.selectedComparePeriod = action.payload.comparePeriod;
    },
    reset: () => INITIAL_STATE,
  },
})

// Action creators are generated for each case reducer function
export const {reset, setPeriods, setSelectedComparePeriod, setSelectedPeriod} = periodsSlice.actions;

export default periodsSlice.reducer;
