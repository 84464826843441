import React, {memo, useEffect, useRef, useState} from 'react'
import {connect, useSelector, useDispatch} from 'react-redux';
import {setDocument} from '../data/documents';
import {withRequiredAuthInfo} from '@propelauth/react';
import {Box, Button, Stack, styled, Typography} from '@mui/material';
import AppPage from './AppPage';
import CommonPageWrapper from '../components/CommonPageWrapper';
import DocumentIcon from '../assets/file-text.svg';
import FileIcon from '../assets/file.svg';
import {
  useCreateSupportingDocumentsDownloadUrlMutation,
  useDeleteDocumentMutation,
  useGetDocumentsQuery,
  useUpdateDocumentMutation,
} from '../data/documentWorkspaceApi';
import TableContainer from '@mui/material/TableContainer';
import {APP_HEADER_HEIGHT, FOOTER_HEIGHT} from '../App';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import TableCell from '@mui/material/TableCell';
import {sortRecords, triggerDownload} from '../util';
import {visuallyHidden} from '@mui/utils';
import _ from 'lodash';
import TableBody from '@mui/material/TableBody';
import Chip from '@mui/material/Chip';
import TimeAgo from '../components/TimeAgo';
import IconButton from '@mui/material/IconButton';
import MoreHoriz from '@mui/icons-material/MoreHoriz';
import {BLACK_100, WHITE_100} from '../theme';
import AddIcon from '@mui/icons-material/Add';
import Error from '../components/Error';
import {withLDConsumer} from 'launchdarkly-react-client-sdk';
import {useNavigate} from 'react-router-dom';
import PropTypes from 'prop-types';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Modal from '@mui/material/Modal';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InputAdornment from '@mui/material/InputAdornment';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Switch from '@mui/material/Switch';
import {bindTrigger, bindMenu, bindPopper, bindHover} from 'material-ui-popup-state';
import {usePopupState} from 'material-ui-popup-state/hooks';
import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton';
import CheckIcon from '@mui/icons-material/Check';
import Loading from '../components/Loading';
import CreateDocumentModal from '../components/documents/CreateDocumentModal';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import HoverPopover from 'material-ui-popup-state/HoverPopover';
import {CREATE_FLOWS, DOCUMENT_TYPES} from '../constants';
import parse from 'html-react-parser';
import {initCollabSocket} from '../util/collabSocketManager';

const TABLE_HEADERS = [
  {
    label: 'Name',
    id: 'name',
    width: 'auto',
    maxWidth: '30.25rem',
  },
  {
    label: 'File type',
    id: 'type',
    width: '12.5rem',
  },
  {
    label: 'Status',
    id: 'status',
    width: '12.5rem',
  },
  {
    label: 'Last modified',
    id: 'updatedAt',
    width: '16.75rem',
  },
];

const styles = {
  createButton: {
    background: '#38CF7A',
    borderRadius: '0.5rem',
    paddingLeft: '0.75rem',
    paddingRight: '1rem',
    paddingVertical: '0.625rem',
    height: '2.5rem',
    '&:hover': {
      backgroundColor: '#38CF7A',
    },
  },
  createButtonLabel: {
    fontSize: '0.875rem',
    lineHeight: '140%',
    fontWeight: '500',
  },
  cancelButton: {
    borderRadius: '0.5rem',
    paddingVertical: '0.6825rem',
    paddingHorizontal: '1rem',
    height: '2.5rem',
    '&:hover': {
      backgroundColor: WHITE_100,
    },
  },
  cancelButtonLabel: {
    color: '#043B34',
    fontSize: '0.875rem',
    fontWeight: '500',
    lineHeight: '130%',
  },
  recentDocuments: {
    label: {
      color: '#043B34',
      fontSize: '1rem',
      fontWeight: '600',
      lineHeight: '120%',
    },
  },
  table: {
    container: {
      borderRadius: '0.25rem',
      border: '1px #DDE0DE',
      background: '#F3F4F4',
    },
    header: {
      cell: {
        height: '2.0625rem',
        backgroundColor: '#F3F4F4',
        padding: '0.5rem 1rem',
        alignItems: 'center',
        marginBottom: '0.75rem',
      },
      label: {
        color: 'rgba(4, 59, 52, 0.70)',
        fontSize: '0.75rem',
        fontWeight: '600',
        lineHeight: '140%',
      },
    },
    row: {
      label: {
        fontSize: '0.875rem',
        fontWeight: '400',
        lineHeight: '140%',
        color: '#043B34',
      },
      secondaryLabel: {
        fontSize: '0.75rem',
        fontWeight: '400',
        lineHeight: '140%',
        color: '#043B34',
        opacity: 0.6,
      },
      editable: {
        '&:hover': {
          textDecoration: 'underline dotted',
        },
      },
      inlineTextField: {
        '.MuiInputBase-root': {
          marginBottom: '-4px',
        },
      },
    },
  },
  menu: {
    base: {
      marginTop: '0.75rem',
      borderRadius: '0.25rem',
      border: '1px #E2E5E7',
      boxShadow: '0px 2px 12px 2px rgba(15, 30, 36, 0.12)',
      '.MuiMenu-list': {
        paddingY: '1rem',
        paddingX: '0.75rem',
      },
      '.MuiList-root': {
        display: 'flex',
        flexDirection: 'column',
        width: '13.75rem',
        gap: '0.5rem',
      },
    },
    popover: {
      borderRadius: '0.25rem',
      border: '1px #E2E5E7',
      boxShadow: '0px 2px 12px 2px rgba(15, 30, 36, 0.12)',
      '.MuiPaper-root': {
        paddingY: '1rem',
        paddingX: '0.75rem',
        display: 'flex',
        flexDirection: 'column',
        width: '13.75rem',
        gap: '0.5rem',
        marginLeft: '0.875rem',
        marginTop: '-1rem',
      },
    },
    quickBase: {
      marginTop: '0.75rem',
      borderRadius: '0.25rem',
      border: '1px #E2E5E7',
      boxShadow: '0px 2px 12px 2px rgba(15, 30, 36, 0.12)',
    },
    listText: {
      primary: {
        fontSize: '0.875rem',
        fontWeight: '400',
        lineHeight: '1.25rem',
        color: '#052E28',
      },
      danger: {
        color: '#EC4B27',
        fontSize: '0.875rem',
        fontWeight: '400',
        lineHeight: '1.25rem',
      },
    },
    item: {
      borderRadius: '0.25rem',
      width: '12.25rem',
      padding: '0.25rem',
      '.MuiListItemIcon-root': {
        minWidth: '2rem',
      },
    },
    quickItem: {
      borderRadius: '0.25rem',
      '.MuiListItemIcon-root': {
        minWidth: '2rem',
      },
    },
  },
  modal: {
    base: {
      borderRadius: '0.5rem',
      boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.15)',
      backgroundColor: WHITE_100,
    },
    title: {
      fontSize: '1.125rem',
      fontWeight: '700',
      lineHeight: '120%',
      color: '#043B34',
    },
    divider: {
      borderBottomWidth: '0.0625rem',
      opacity: 1,
      color: '#DDe0DE',
    },
    label: {
      fontSize: '0.8125rem',
      fontWeight: '500',
      lineHeight: '150%',
      color: '#043B34',
    },
    heavy: {
      fontSize: '0.8125rem',
      fontWeight: '700',
      lineHeight: '150%',
      color: '#043B34',
    },
  },
}

const StyledTableRow = styled(TableRow)(() => ({
  '&:hover': {
    backgroundColor: '#EBFAF1 !important',
  },
  '&:nth-of-type(odd)': {
    backgroundColor: WHITE_100,
  },
  '&:nth-of-type(even)': {
    backgroundColor: WHITE_100,
  },
}));

const CascadingContext = React.createContext({
  parentPopupState: null,
  rootPopupState: null,
})

const CascadingSubmenu = ({children, id, leftIcon, rightIcon, listItemText, ...props}) => {
  const {parentPopupState} = React.useContext(CascadingContext)
  const popupState = usePopupState({variant: 'popover', popupId: id, parentPopupState});

  return (
    <MenuItem
      sx={styles.menu.item}
      {...props}
      {...bindHover(popupState)}
    >
      {leftIcon && <ListItemIcon>{leftIcon}</ListItemIcon>}
      <ListItemText primaryTypographyProps={styles.menu.listText.primary}>{listItemText}</ListItemText>
      {rightIcon && <ListItemIcon sx={{justifyContent: 'flex-end'}}>{rightIcon}</ListItemIcon>}
      {!rightIcon && <Box width={'2rem'} />}
      <HoverPopover
        {...bindPopper(popupState)}
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        transformOrigin={{vertical: 'top', horizontal: 'left'}}
        sx={styles.menu.popover}
      >
        {children}
      </HoverPopover>
    </MenuItem>
  )
}

CascadingSubmenu.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired,
  leftIcon: PropTypes.node,
  rightIcon: PropTypes.node,
  listItemText: PropTypes.string.isRequired,
}

const CascadingMenu = ({popupState, ...props}) => {
  const {rootPopupState} = React.useContext(CascadingContext)
  const context = React.useMemo(
    () => ({
      rootPopupState: rootPopupState || popupState,
      parentPopupState: popupState,
    }),
    [rootPopupState, popupState],
  )

  return (
    <CascadingContext.Provider value={context}>
      <Menu {...props} {...bindMenu(popupState)} />
    </CascadingContext.Provider>
  )
}

CascadingMenu.propTypes = {
  popupState: PropTypes.object.isRequired,
}

const CreateDocumentMenu = ({onClick}) => {
  const popupState = usePopupState({variant: 'popover', popupId: 'createDocumentMenu'});
  const _handleClick = (createFlow) => () => {
    onClick(createFlow);
    popupState.close();
  }

  return (
    <div>
      <Button variant={'contained'} startIcon={<AddIcon />} sx={styles.createButton} {...bindTrigger(popupState)}><Typography style={styles.createButtonLabel}>Create new</Typography></Button>
      <CascadingMenu popupState={popupState} anchorOrigin={{vertical: 'bottom', horizontal: 'right'}} transformOrigin={{vertical: 'top', horizontal: 'right'}} sx={styles.menu.base}>
        <CascadingSubmenu id={'AnnualReport'} leftIcon={<img src={DocumentIcon} alt={''}/>} listItemText={'Annual report'} rightIcon={<ChevronRightIcon size={'small'} />}>
          <MenuItem onClick={_handleClick(CREATE_FLOWS.annualRollForward)} sx={styles.menu.item}>
            <ListItemText primaryTypographyProps={styles.menu.listText.primary}>Roll forward prior report...</ListItemText>
          </MenuItem>
          <MenuItem onClick={_handleClick(CREATE_FLOWS.annualBlankDocument)} sx={styles.menu.item}>
            <ListItemText primaryTypographyProps={styles.menu.listText.primary}>Blank document</ListItemText>
          </MenuItem>
        </CascadingSubmenu>
        <MenuItem onClick={_handleClick(CREATE_FLOWS.quarterly)} sx={styles.menu.item}>
          <ListItemIcon><img src={DocumentIcon} alt={''}/></ListItemIcon>
          <ListItemText primaryTypographyProps={styles.menu.listText.primary}>Quarterly report</ListItemText>
        </MenuItem>
        <MenuItem onClick={_handleClick(CREATE_FLOWS.other)} sx={styles.menu.item}>
          <ListItemIcon><img src={FileIcon} alt={''}/></ListItemIcon>
          <ListItemText primaryTypographyProps={styles.menu.listText.primary}>Basic document</ListItemText>
        </MenuItem>
      </CascadingMenu>
    </div>
  )
}

CreateDocumentMenu.propTypes = {
  onClick: PropTypes.func.isRequired,
}

const DeleteDocumentModal = ({rowData, open, onClose, onSuccess, onError}) => {
  const [loading, setLoading] = useState(false);
  const [deleteDocument, {isSuccess, isError, reset}] = useDeleteDocumentMutation();

  useEffect(() => {
    if (isSuccess) {
      onSuccess();
      onClose();
      reset();
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      onError();
      reset();
    }
  }, [isError]);

  const _deleteDocument = async () => {
    setLoading(true);
    await deleteDocument({
      documentId: rowData.documentId,
    });
    setLoading(false);
  }

  return (
    <Modal open={open} onClose={onClose}>
      <Box position={'absolute'} sx={{left: '50%', top: '50%', transform: 'translate(-50%, -50%)'}}>
        <Box width={'28rem'} minWidth={'28rem'} maxWidth={'28rem'}>
          <Stack direction={'column'} sx={styles.modal.base}>
            <Stack direction={'row'} paddingY={'1rem'} paddingLeft={'1.5rem'} paddingRight={'1rem'} flex={1} justifyContent={'space-between'} alignItems={'center'}>
              <Typography sx={styles.modal.title}>Delete file</Typography>
              <IconButton onClick={onClose}><CloseIcon /></IconButton>
            </Stack>
            <Divider orientation={'horizontal'} sx={styles.modal.divider} />
            <Stack direction={'column'} paddingTop={'1.25rem'} paddingBottom={'1.5rem'} paddingX={'1.5rem'} spacing={'1rem'}>
              <Typography component={'div'} style={styles.modal.label}>You’re about to delete the file <Typography component={'span'} style={styles.modal.heavy}>{rowData.name}</Typography>.</Typography>
              <Typography style={styles.modal.label}>This action is permanent and can’t be undone.</Typography>
            </Stack>
            <Divider orientation={'horizontal'} sx={styles.modal.divider} />
            <Stack direction={'row'} paddingY={'1rem'} paddingLeft={'1.5rem'} paddingRight={'1rem'} flex={1} justifyContent={'flex-end'} alignItems={'center'} spacing={'0.5rem'}>
              <Button variant={'outlined'} sx={styles.cancelButton} onClick={onClose}><Typography style={styles.cancelButtonLabel}>Cancel</Typography></Button>
              <LoadingButton variant={'contained'} sx={{...styles.createButton, width: '9.3125rem'}} onClick={_deleteDocument} loading={loading} loadingIndicator={<Loading loading={loading} loadingProps={{color: BLACK_100}} />}><Typography style={styles.createButtonLabel}>Delete document</Typography></LoadingButton>
            </Stack>
          </Stack>
        </Box>
      </Box>
    </Modal>
  )
}

DeleteDocumentModal.propTypes = {
  rowData: PropTypes.shape({
    documentId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
}

const QuickActionMenu = ({rowData, onDocumentClose, reset, onResetComplete, showDocumentDeleteModal, showRenameFile, closeMenu, oncloseMenuComplete, onOpen, showRollForwardModal}) => {
  const popupState = usePopupState({variant: 'popover', popupId: 'createDocumentMenu'});
  const [createSupportingDocumentsDownloadUrl, {data, isSuccess, isError, error}] = useCreateSupportingDocumentsDownloadUrlMutation();
  const [status, setStatus] = useState(rowData.status);

  useEffect(() => {
    setStatus(rowData.status);
  }, [rowData]);

  useEffect(() => {
    setStatus(rowData.status);
    onResetComplete();
  }, [reset]);

  useEffect(() => {
    popupState.close();
    oncloseMenuComplete();
  }, [closeMenu]);

  useEffect(() => {
    if (isSuccess) {
      triggerDownload(() => ({signedUrl: data.supportingDocumentsSignedUrl}), `${rowData.name} - [Supporting Documents].zip`);
      popupState.close();
    } else if (isError) {
      console.error('Error downloading supporting document', {error});
    }
  }, [data, isSuccess, isError, error]);

  const _handleDocumentClose = () => {
    const _newStatus = status === 'FINAL' ? 'DRAFT' : 'FINAL';
    setStatus(_newStatus);
    onDocumentClose(_newStatus);
  }

  const _showRenameFile = () => {
    popupState.close();
    showRenameFile();
  }

  const _onOpen = () => {
    popupState.close();
    onOpen();
  }

  const _showRollForwardModal = () => {
    popupState.close();
    showRollForwardModal();
  }

  const _downloadSupportingDocuments = () => {
    createSupportingDocumentsDownloadUrl({documentId: rowData.documentId});
  }

  return (
    <div data-component={'QuickActionMenu'} data-file-source={'DocumentPage.jsx'}>
      <IconButton {...bindTrigger(popupState)}>
        <MoreHoriz />
      </IconButton>
      <Menu {...bindMenu(popupState)} anchorOrigin={{vertical: 'bottom', horizontal: 'right'}} transformOrigin={{vertical: 'top', horizontal: 'right'}} sx={styles.menu.quickBase}>
        <MenuItem sx={styles.menu.quickItem} onClick={_onOpen}>
          <ListItemText primaryTypographyProps={styles.menu.listText.primary}>Open</ListItemText>
        </MenuItem>
        {_.has(rowData, 'supportingDocumentsS3Key') && !_.isEmpty('rowData.supportingDocumentsS3Key') && (
          <MenuItem sx={styles.menu.quickItem} onClick={_downloadSupportingDocuments}>
            <ListItemText primaryTypographyProps={styles.menu.listText.primary}>Download roll-forward Excel files</ListItemText>
          </MenuItem>
        )}
        {rowData.type === DOCUMENT_TYPES.annualReport && (
          <MenuItem sx={styles.menu.quickItem} onClick={_showRollForwardModal}>
            <ListItemText primaryTypographyProps={styles.menu.listText.primary}>Roll forward</ListItemText>
          </MenuItem>
        )}
        <Divider orientation={'horizontal'} sx={styles.modal.divider} />
        {rowData.status !== 'PENDING' && (
          <span>
            <MenuItem sx={styles.menu.quickItem}>
              <Stack direction={'row'} alignItems={'center'} gap={'1.5rem'}>
                <ListItemText primaryTypographyProps={styles.menu.listText.primary}>Set status to closed</ListItemText>
                <Switch checked={status === 'FINAL'} size={'small'} onChange={_handleDocumentClose} />
              </Stack>
            </MenuItem>
            <Divider orientation={'horizontal'} sx={styles.modal.divider} />
          </span>
        )}
        <MenuItem sx={styles.menu.quickItem} onClick={_showRenameFile}>
          <ListItemText primaryTypographyProps={styles.menu.listText.primary}>Rename</ListItemText>
        </MenuItem>
        <MenuItem sx={styles.menu.quickItem} onClick={showDocumentDeleteModal}>
          <ListItemText primaryTypographyProps={styles.menu.listText.danger}>Delete</ListItemText>
        </MenuItem>
      </Menu>
    </div>
  )
}

QuickActionMenu.propTypes = {
  rowData: PropTypes.shape({
    status: PropTypes.string.isRequired,
    documentId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
  onDocumentClose: PropTypes.func.isRequired,
  reset: PropTypes.bool.isRequired,
  onResetComplete: PropTypes.func.isRequired,
  showDocumentDeleteModal: PropTypes.func.isRequired,
  showRenameFile: PropTypes.func.isRequired,
  closeMenu: PropTypes.bool.isRequired,
  oncloseMenuComplete: PropTypes.func.isRequired,
  onOpen: PropTypes.func.isRequired,
  showRollForwardModal: PropTypes.func.isRequired,
}

const DocumentRow = memo(({idx, rowData, onError, onEditIsOpen, editIsOpen, onOpen, showRollForwardModal}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [editDocumentName, setEditDocumentName] = useState(false);
  const [documentName, setDocumentName] = useState(_.get(rowData, 'name', ''));
  const [newDocumentName, setNewDocumentName] = useState(_.get(rowData, 'name', ''));
  const [documentStatus, setDocumentStatus] = useState(rowData.status);
  const [resetQuickMenu, setResetQuickMenu] = useState(false);
  const [closeQuickMenu, setCloseQuickMenu] = useState(false);
  const [showDocumentDeleteModal, setShowDocumentDeleteModal] = useState(false);
  const [updateDocument, {isSuccess, isError, error, reset}] = useUpdateDocumentMutation();
  const inputRef = useRef(null);

  const handleClickOutside = event => {
    if (inputRef.current && !inputRef.current.contains(event.target)) {
      setEditDocumentName(false);
    }
  }

  useEffect(() => {
    if (editDocumentName) {
      if (inputRef.current) {
        inputRef.current.querySelector('input').select();
      }
      onEditIsOpen(true);
      document.addEventListener('click', handleClickOutside, true);
      return () => {
        document.removeEventListener('click', handleClickOutside, true);
      };
    } else {
      onEditIsOpen(false);
    }
  }, [editDocumentName]);

  useEffect(() => {
    setDocumentName(rowData.name);
    setNewDocumentName(rowData.name);
    setDocumentStatus(rowData.status);
  }, [rowData]);

  useEffect(() => {
    if (isSuccess) {
      setCloseQuickMenu(true);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      setDocumentName(rowData.name);
      setDocumentStatus(rowData.status);
      setNewDocumentName(rowData.name);
      setResetQuickMenu(true);
      if (_.get(error, 'data.code') === 'DOCUMENT_EXISTS') {
        onError('Filename exists. Please choose another.');
      } else {
        onError();
      }
      reset();
    }
  }, [isError]);

  const _renderFileType = line => {
    switch (line.type) {
      case 'ANNUAL':
        if (_.has(line, 'fiscalYear')) {
          return `Annual report (${line.fiscalYear})`;
        }
        return 'Annual report';
      case 'QUARTER':
        if (_.has(line, 'fiscalYear') && _.has(line, 'quarter')) {
          return `Quarterly report (${line.quarter} ${line.fiscalYear})`;
        } else if (_.has(line, 'fiscalYear')) {
          return `Quarterly report (${line.fiscalYear})`;
        }
        return 'Quarterly report';
      default:
        return 'Basic document';
    }
  }

  const _getChipDetails = status => {
    switch (status) {
      case 'FINAL':
        return {
          label: 'Closed - Filed',
          variant: 'closed',
        }
      case 'PENDING':
        return {
          label: 'Processing roll forward',
          variant: 'pending',
        }
      default:
        return {
          label: 'Open - Draft',
          variant: 'open',
        }
    }
  }

  const _editDocumentName = event => {
    event.stopPropagation();
    setEditDocumentName(!editDocumentName);
    onEditIsOpen(false);
  }

  const _handleDocumentNameChange = event => {
    setNewDocumentName(event.target.value);
  }

  const _updateDocumentName = async event => {
    setDocumentName(newDocumentName);
    _editDocumentName(event);
    await updateDocument({documentId: rowData.documentId, data: {name: newDocumentName.trim()}});
  }

  const _handleCellClick = () => {
    if (_.get(rowData, 'status') === 'PENDING') {
      onOpen({existingDocumentId: rowData.documentId, initialStep: 'PROCESSING'});
    } else if (!editDocumentName && !editIsOpen) {
      navigate(`/documents/${rowData.documentId}`);
    }

    dispatch(setDocument({document: rowData}));
  }

  const _handleDocumentClose = async (status) => {
    setDocumentStatus(status);
    await updateDocument({documentId: rowData.documentId, data: {status}});
  }

  const _handleDeleteDocumentModalSuccess = () => {
    setCloseQuickMenu(true);
  }

  const _handleKeyPress = async (event) => {
    switch (event.key) {
      case 'Enter':
        setDocumentName(newDocumentName);
        setEditDocumentName(false);
        await updateDocument({documentId: rowData.documentId, data: {name: newDocumentName}});
        break;
      case 'Escape':
        setEditDocumentName(false);
        break;
      default:
        break;
    }
  }

  return (
    <React.Fragment>
      <StyledTableRow key={idx} sx={{cursor: editDocumentName ? 'default' : 'pointer'}}>
        <TableCell sx={{width: TABLE_HEADERS[0].width, maxWidth: TABLE_HEADERS[0].maxWidth}} onClick={_handleCellClick}>
          <Stack direction={'row'} spacing={1} alignItems={'center'}>
            <img src={DocumentIcon} alt={''}/>
            {!editDocumentName && <Typography noWrap sx={styles.table.row.label}>{parse(documentName)}</Typography>}
            {editDocumentName && (
              <TextField
                ref={inputRef}
                size={'small'}
                inputRef={input => input && input.focus()}
                sx={styles.table.row.inlineTextField}
                variant={'standard'}
                value={newDocumentName}
                onChange={_handleDocumentNameChange}
                fullWidth
                onKeyUp={_handleKeyPress}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="confirm name change"
                        onClick={_updateDocumentName}
                        edge="end"
                        size={'small'}
                      >
                        <CheckIcon fontSize={'inherit'} />
                      </IconButton>
                      <IconButton
                        aria-label="confirm name change"
                        onClick={_editDocumentName}
                        edge="end"
                        size={'small'}
                      >
                        <CloseIcon fontSize={'inherit'} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          </Stack>
        </TableCell>
        <TableCell sx={{width: TABLE_HEADERS[1].width}} onClick={_handleCellClick}>
          <Typography sx={styles.table.row.label}>{_renderFileType(rowData)}</Typography>
        </TableCell>
        <TableCell sx={{width: TABLE_HEADERS[2].width}} onClick={_handleCellClick}>
          <Chip size={'small'} {..._getChipDetails(documentStatus)} />
        </TableCell>
        <TableCell sx={{width: TABLE_HEADERS[3].width}} onClick={_handleCellClick}>
          <Stack direction={'column'}>
            <Typography sx={styles.table.row.label}><TimeAgo timestamp={rowData.updatedAt} /></Typography>
            <Typography sx={styles.table.row.secondaryLabel}>By {rowData.updatedByName}</Typography>
          </Stack>
        </TableCell>
        <TableCell sx={{width: '4.5rem'}}>
          <QuickActionMenu
            rowData={rowData}
            onDocumentClose={_handleDocumentClose}
            reset={resetQuickMenu}
            onResetComplete={() => setResetQuickMenu(false)}
            showDocumentDeleteModal={() => setShowDocumentDeleteModal(true)}
            showRenameFile={() => setEditDocumentName(true)}
            closeMenu={closeQuickMenu}
            oncloseMenuComplete={() => setCloseQuickMenu(false)}
            onOpen={_handleCellClick}
            showRollForwardModal={showRollForwardModal(rowData.documentId)}
          />
        </TableCell>
      </StyledTableRow>
      <DeleteDocumentModal rowData={rowData} open={showDocumentDeleteModal} onClose={() => setShowDocumentDeleteModal(false)} onSuccess={_handleDeleteDocumentModalSuccess} onError={onError} />
    </React.Fragment>
  )
});

DocumentRow.displayName = 'DocumentRow';
DocumentRow.propTypes = {
  idx: PropTypes.number.isRequired,
  rowData: PropTypes.shape({
    documentId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    updatedAt: PropTypes.string.isRequired,
    updatedByName: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    fiscalYear: PropTypes.string,
    quarter: PropTypes.string,
    status: PropTypes.string,
  }),
  onError: PropTypes.func.isRequired,
  onEditIsOpen: PropTypes.func.isRequired,
  editIsOpen: PropTypes.bool.isRequired,
  onOpen: PropTypes.func.isRequired,
  showRollForwardModal: PropTypes.func.isRequired,
}

class DocumentsPage extends AppPage {
  constructor (props) {
    super(props);
    this.state = {
      showCreateReportModal: false,
      createFlow: '',
      documentNameIsDirty: false,
    }
  }

  _onClick = (createFlow) => {
    this.setState({
      createFlow,
      showCreateReportModal: true,
    });
  }

  _showCreateModal = (show = false, createFlow = '') => {
    this.setState({
      createFlow,
      showCreateReportModal: show,
    });
  }

  _setCreateFlow = (createFlow) => {
    this.setState({createFlow});
  }

  getPageTitle = () => 'Documents';

  renderBodyContent = () => {
    return (
      <React.Fragment>
        <DocumentsPageComponent createFlow={this.state.createFlow} showCreateReportModal={this.state.showCreateReportModal} showCreateModal={this._showCreateModal} />
      </React.Fragment>
    )
  }

  renderCustomHeaderContent = () => {
    return (
      <Stack direction={'row'} flex={1} justifyContent={'flex-end'}>
        <CreateDocumentMenu onClick={this._onClick} />
      </Stack>
    )
  }
}

const DocumentsPageComponent = ({createFlow, showCreateReportModal, showCreateModal}) => {
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('updatedAt');
  const [sortedTableData, setSortedTableData] = useState([]);
  const [skip, setSkip] = useState(true);
  const {currentOrg, isAuthenticated} = useSelector((state) => state.session);
  const {data, isLoading, isError, isSuccess, refetch} = useGetDocumentsQuery({}, {skip, refetchOnMountOrArgChange: true, pollingInterval: 30000});
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [editIsOpen, setEditIsOpen] = useState(false);
  const [previousAnnualReports, setPreviousAnnualReports] = useState(null);
  const [previousDocumentId, setPreviousDocumentId] = useState(null);
  const [existingDocumentId, setExistingDocumentId] = useState(null);

  useEffect(() => {
    initCollabSocket();
  }, []);

  useEffect(() => {
    if (!showCreateReportModal) {
      setExistingDocumentId(null);
      setPreviousDocumentId(null);
    }
  }, [showCreateReportModal]);

  useEffect(() => {
    setSkip(_.isNil(currentOrg) || _.isEmpty(currentOrg) || !isAuthenticated);
  }, [currentOrg, isAuthenticated]);

  useEffect(() => {
    setSortedTableData(sortRecords(data || [], order, orderBy));
    const _sortedAnnualReports = _.orderBy(_.filter(data, {type: DOCUMENT_TYPES.annualReport}), ['fiscalYear', 'name'], ['desc', 'asc']);
    setPreviousAnnualReports(_sortedAnnualReports);
  }, [data, order, orderBy]);

  const _onShowError = (errorMessage = 'There was an error processing your request. Please try again later.') => {
    setErrorMessage(errorMessage);
    setShowError(true);
  }

  const _onHideError = () => {
    setErrorMessage('');
    setShowError(false);
  }

  const _handleRequestSort = property => () => {
    const _isAsc = orderBy === property && order === 'asc';
    setOrder(_isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  }

  const _renderError = () => {
    if (!isLoading && isError) {
      return (
        <Error hasError={isError} message={'There was an error processing your request. Please try again later.'} />
      )
    }
  }

  const _onOpen = ({existingDocumentId}) => {
    setExistingDocumentId(existingDocumentId);
    showCreateModal(true, CREATE_FLOWS.annualRollForward);
  }

  const _showRollForwardModal = (documentId) => () => {
    setPreviousDocumentId(documentId);
    showCreateModal(true, CREATE_FLOWS.annualRollForward);
  }

  const _renderTable = () => {
    if (!isLoading && isSuccess) {
      return (
        <Stack direction={'column'} spacing={'0.75rem'}>
          <TableContainer sx={styles.table.container}>
            <Table>
              <TableHead cellSpacing={'2.5rem'}>
                <TableRow sx={{borderRadius: '0.25rem', border: '1px #DDE0DE', background: '#F3F4F4'}}>
                  {_.map(TABLE_HEADERS, _header => (
                    <TableCell key={_header.id} variant={'head'} sortDirection={orderBy === _header.id ? order : false} sx={{...styles.table.header.cell, width: _header.width}}>
                      <TableSortLabel
                        active={orderBy === _header.id}
                        direction={orderBy === _header.id ? order : 'asc'}
                        onClick={_handleRequestSort(_header.id)}>
                        <Typography sx={styles.table.header.label}>{_header.label}</Typography>
                        {orderBy === _header.id
                          ? (
                            <Box component={'span'} sx={visuallyHidden}>
                              {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                            )
                          : null}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                  <TableCell sx={{...styles.table.header.cell, width: '4.5rem'}} />
                </TableRow>
              </TableHead>
            </Table>
          </TableContainer>
          <TableContainer sx={{maxHeight: `calc(100vh - ${APP_HEADER_HEIGHT} - ${FOOTER_HEIGHT} - 5.075rem - 2.06rem - 1rem - 1.5rem - 2.28125rem - 0.75rem - 2rem)`}}>
            <Table stickyHeader>
              <TableBody sx={{marginTop: '0.75rem'}}>
                {_.map(sortedTableData, (_data, _idx) => {
                  return <DocumentRow key={_idx} idx={_idx} rowData={_data} onError={_onShowError} onEditIsOpen={setEditIsOpen} editIsOpen={editIsOpen} onOpen={_onOpen} showRollForwardModal={_showRollForwardModal} />
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      )
    }
  }

  const _onRollForwardComplete = () => {
    refetch();
  }

  return (
    <CommonPageWrapper height={'100%'} pt={'2.06rem'} data-component={'DocumentsPageComponent'} data-file-source={'DocumentsPage.jsx'}>
      <Stack direction={'row'} flex={1} height={'100%'}>
        <Stack direction={'column'} flex={1} spacing={'1.5rem'}>
          <Typography sx={styles.recentDocuments.label}>Recent documents</Typography>
          <Loading loading={isLoading} />
          {_renderError()}
          {_renderTable()}
        </Stack>
      </Stack>
      <CreateDocumentModal open={showCreateReportModal} onClose={showCreateModal} onError={_onShowError} previousDocuments={previousAnnualReports} previousDocumentId={previousDocumentId} existingDocumentId={existingDocumentId} createFlow={createFlow} onRollForwardComplete={_onRollForwardComplete} />
      <Snackbar open={showError} autoHideDuration={6000} onClose={_onHideError} anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}>
        <Alert
          onClose={_onHideError}
          severity={'error'}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </CommonPageWrapper>
  )
}

const mapStateToProps = (state) => {
  return {
    session: state.session,
  }
}

DocumentsPageComponent.propTypes = {
  createFlow: PropTypes.string.isRequired,
  showCreateReportModal: PropTypes.bool.isRequired,
  showCreateModal: PropTypes.func.isRequired,
}

export default withLDConsumer()(connect(mapStateToProps)(withRequiredAuthInfo(DocumentsPage)));
