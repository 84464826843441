import Typography from '@mui/material/Typography';
import React from 'react';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import {getPeriodEndDate, getPeriodTimeframe} from '../../util';
import spacetime from 'spacetime';
import _ from 'lodash';

const TableHeaderDateCell = ({period, generatedPeriod, reportType, isReviewMode, dateCreated, isUnpublished, ...stackProps}) => {
  return (
    <Stack direction={'column'} justifyContent={'flex-end'} {...stackProps} >
      {reportType !== 'BALANCE_SHEET' && (
        <Typography align={'right'} fontSize={'0.75rem'} fontWeight={400} lineHeight={'1.25rem'}>{getPeriodTimeframe(period, generatedPeriod)}</Typography>
      )}
      <Typography align={'right'} fontSize={'0.875rem'} fontWeight={600} lineHeight={'1.25rem'}>{period ? getPeriodEndDate(period, generatedPeriod) : ''}</Typography>
      {isReviewMode && !_.isNil(dateCreated) && (
        <Typography align={'right'} fontSize={'0.75rem'} fontWeight={400} lineHeight={'1.125rem'}>Run: {spacetime(dateCreated).format('{numeric-us} @ {time}')}{isUnpublished && ' (unpublished)'}</Typography>
      )}
    </Stack>
  )
}

TableHeaderDateCell.propTypes = {
  period: PropTypes.shape({
    endDate: PropTypes.string.isRequired,
    periodType: PropTypes.string.isRequired,
  }).isRequired,
  generatedPeriod: PropTypes.shape({
    endDate: PropTypes.string.isRequired,
    periodType: PropTypes.string.isRequired,
  }),
  reportType: PropTypes.string.isRequired,
  isReviewMode: PropTypes.bool.isRequired,
  dateCreated: PropTypes.string,
  isUnpublished: PropTypes.bool,
}

export default TableHeaderDateCell;
