import React from 'react';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import {APP_HEADER_HEIGHT, FOOTER_HEIGHT} from '../App';
import Paper from '@mui/material/Paper';
import Loading from './Loading';
import Error from './Error';
import NoData from './NoData';
import Box from '@mui/material/Box';

const CompanyConfigTable = ({hasError, errorMessage, loading, success, hasData, screen, children}) => {
  return (
    <Paper elevation={2} sx={{width: '100%'}}>
      <Box display={'flex'} justifyContent={'center'} alignItems={'center'} minHeight={'18.75rem'} width={'100%'}>
        <Loading loading={loading} message={`Loading ${screen}`} />
        <Error hasError={!loading && hasError} message={errorMessage} />
        {!loading && success && (
          <>
            <NoData hidden={hasData} screen={screen} />
            {hasData && (
              <Stack direction={'column'} flex={1} width={'100%'} height={`calc(100vh - ${FOOTER_HEIGHT} - ${APP_HEADER_HEIGHT} - 3rem)`} justifyContent={'center'} alignItems={'center'}>
                {children}
              </Stack>
            )}
          </>
        )}
      </Box>
    </Paper>
  )
}

CompanyConfigTable.propTypes = {
  hasError: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  success: PropTypes.bool.isRequired,
  hasData: PropTypes.bool.isRequired,
  screen: PropTypes.string.isRequired,
  children: PropTypes.any,
}

export default CompanyConfigTable;
