import React, {useEffect, useState} from 'react';
import _ from 'lodash';
import {renderAmount} from '../util';
import {BLACK_100, GREEN_100, GREEN_70, GREEN_95, WHITE_100} from '../App';
import TableRow from '@mui/material/TableRow';
import TableCell from './TableCell';
import {IconButton, Stack, styled} from '@mui/material';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import DetailTable from './DetailTable';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {useSelector} from 'react-redux';
import Decimal from 'decimal.js-light';

const StyledTabs = styled(Tabs)(() => ({
  '& .MuiTabs-indicator': {
    height: 0,
  },
}));

const StyledTab = styled(Tab)(() => ({
  '&.Mui-selected': {
    backgroundColor: GREEN_95,
    color: WHITE_100,
    border: 0,
    borderRadius: '0.25rem',
  },
  '&:hover:not(.Mui-selected)': {
    backgroundColor: GREEN_70,
    color: GREEN_100,
    borderRadius: '0.25rem',
  },
  textTransform: 'capitalize',
}));

const IncomeStatementDetailRow = ({idx, row, numRecords, hasHeader, compareEndDate, collapsable = false, isLast = false, openState, groupBy}) => {
  const [open, isOpen] = useState(openState);
  const {reviewMode, hasUnpublishedReports} = useSelector((state) => state.reports);
  const _bgColor = isLast ? GREEN_95 : null;
  const _cellStyle = idx === numRecords && hasHeader ? {border: 0, borderTopWidth: 1, borderColor: BLACK_100} : {border: 0};

  useEffect(() => {
    isOpen(openState);
  }, [openState])

  const _typographyStyle = {};
  if (isLast) {
    _typographyStyle.fontSize = '0.875rem';
    _typographyStyle.fontWeight = '600';
    _typographyStyle.lineHeight = '130%';
    _typographyStyle.letterSpacing = '0.0175rem';
    _typographyStyle.color = WHITE_100;
  }

  if (_.has(row, 'children') && collapsable) {
    return (
      <React.Fragment>
        <TableRow key={idx} sx={{height: '3.125rem', borderWidth: 0, marginX: 0, paddingX: 0}}>
          {groupBy === 'label' && (
            <TableCell sx={{border: 0, paddingLeft: groupBy === 'label' && collapsable ? 0 : '2.5rem'}}>
              <Stack direction={'row'} alignItems={'center'}>
                <Box minWidth={'2.5rem'} display={'flex'} justifyContent={'center'}>
                  <IconButton size={'small'} onClick={() => isOpen(!open)}>
                    {open && <ExpandMoreIcon />}
                    {!open && <ChevronRightIcon />}
                  </IconButton>
                </Box>
                <Typography>{row.label}</Typography>
              </Stack>
            </TableCell>
          )}
          {groupBy === 'account' && (
            <TableCell sx={{border: 0, paddingLeft: groupBy === 'account' && collapsable ? 0 : '2.5rem'}}>
              <Stack direction={'row'} alignItems={'center'}>
                <Box minWidth={'2.5rem'} display={'flex'} justifyContent={'center'}>
                  <IconButton size={'small'} onClick={() => isOpen(!open)}>
                    {open && <ExpandMoreIcon />}
                    {!open && <ChevronRightIcon />}
                  </IconButton>
                </Box>
                <Typography>{row.label}</Typography>
              </Stack>
            </TableCell>
          )}
          <TableCell sx={_cellStyle}>
            <Stack direction={'row'} justifyContent={'space-between'}>
              <Typography>{(idx === 0 || idx === numRecords) && _.isNumber(row.amount) ? '$' : ''}</Typography>
              <Typography>{renderAmount(row.amount)}</Typography>
            </Stack>
          </TableCell>
          {(!_.isEmpty(compareEndDate) || (reviewMode && hasUnpublishedReports)) && (
            <TableCell sx={_cellStyle}>
              <Stack direction={'row'} justifyContent={'space-between'}>
                <Typography>{(idx === 0 || idx === numRecords) && _.isNumber(row.compareAmount) ? '$' : ''}</Typography>
                <Typography>{renderAmount(row.compareAmount)}</Typography>
              </Stack>
            </TableCell>
          )}
        </TableRow>
        {open && (
          _.map(row.children, (_row, _idx) => {
            return (
              <TableRow key={`${idx}.${_idx}`} sx={{height: '3.125rem', borderWidth: 0, marginX: 0, paddingX: 0}}>
                {groupBy === 'label' && (
                  <TableCell sx={{border: 0, paddingLeft: '5rem'}}>
                    <Stack direction={'row'} alignItems={'center'}>
                      <Typography>Acct {_row.account}</Typography>
                    </Stack>
                  </TableCell>
                )}
                {groupBy === 'account' && (
                  <TableCell sx={{border: 0, paddingLeft: '5rem'}}>
                    <Stack direction={'row'} alignItems={'center'}>
                      <Typography>Dept {_row.department || _row.label}</Typography>
                    </Stack>
                  </TableCell>
                )}
                <TableCell sx={_cellStyle}>
                  <Stack direction={'row'} justifyContent={'space-between'}>
                    <Typography />
                    <Typography>{renderAmount(_row.amount)}</Typography>
                  </Stack>
                </TableCell>
                {(!_.isEmpty(compareEndDate) || (reviewMode && hasUnpublishedReports)) && (
                  <TableCell sx={_cellStyle}>
                    <Stack direction={'row'} justifyContent={'space-between'}>
                      <Typography />
                      <Typography>{renderAmount(_row.compareAmount)}</Typography>
                    </Stack>
                  </TableCell>
                )}
              </TableRow>
            )
          })
        )}
      </React.Fragment>
    )
  } else {
    return (
      <TableRow key={idx} sx={{height: '3.125rem', borderWidth: 0, marginX: 0, paddingX: 0}}>
        <TableCell sx={{border: 0, bgcolor: _bgColor, paddingY: isLast ? '1rem' : '0.5rem'}}>
          <Stack direction={'row'} alignItems={'center'}>
            <Typography {..._typographyStyle}>{row.department || row.label}</Typography>
          </Stack>
        </TableCell>
        <TableCell sx={{..._cellStyle, bgcolor: _bgColor}}>
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Typography {..._typographyStyle}>{(idx === 0 || idx === numRecords) && _.isNumber(row.amount) ? '$' : ''}</Typography>
            <Typography {..._typographyStyle}>{renderAmount(row.amount)}</Typography>
          </Stack>
        </TableCell>
        {((!reviewMode && !_.isEmpty(compareEndDate)) || (reviewMode && hasUnpublishedReports)) && (
          <TableCell sx={{..._cellStyle, bgcolor: _bgColor}}>
            <Stack direction={'row'} justifyContent={'space-between'}>
              <Typography {..._typographyStyle}>{(idx === 0 || idx === numRecords) && _.isNumber(row.compareAmount) ? '$' : ''}</Typography>
              <Typography {..._typographyStyle}>{renderAmount(row.compareAmount)}</Typography>
            </Stack>
          </TableCell>
        )}
      </TableRow>
    )
  }
}

IncomeStatementDetailRow.propTypes = {
  idx: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  row: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        department: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        account: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        amount: PropTypes.oneOfType([PropTypes.number, PropTypes.object]).isRequired,
        compareTotal: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
      }),
    ),
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      department: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      account: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      amount: PropTypes.oneOfType([PropTypes.number, PropTypes.object]).isRequired,
      compareTotal: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
    }),
  ]),
  numRecords: PropTypes.number,
  hasHeader: PropTypes.bool,
  compareEndDate: PropTypes.string,
  collapsable: PropTypes.bool,
  isLast: PropTypes.bool,
  openState: PropTypes.bool,
  groupBy: PropTypes.string,
}

const IncomeStatementDetailTable = ({tableData, totalRow, oobRow, numRecords, period, comparePeriod, generatedPeriod, hasHeader = true, reportType, dataLoaded}) => {
  const [groupBy, setGroupBy] = useState('label');
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('label');
  const [sortedTableData, setSortedTableData] = useState(tableData);
  const [expandAll, setExpandAll] = useState(false);

  useEffect(() => {
    if (dataLoaded) {
      const _orderedRecords = _.orderBy(tableData, [orderBy], [order]);
      const _groupedRecords = _.groupBy(_orderedRecords, _r => _r[groupBy]);
      const _augmentedRecords = _.map(_groupedRecords, (_data, _key) => (
        {
          label: _key,
          children: _data,
          amount: _.sumBy(_data, 'amount'),
          compareAmount: _.sumBy(_data, 'compareAmount'),
        }
      ));
      setSortedTableData(_.orderBy(_augmentedRecords, [orderBy], [order]));
    } else {
      setSortedTableData(tableData);
    }
  }, [tableData, order, orderBy, groupBy, dataLoaded])

  const _handleRequestSort = (property) => () => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  }

  const _expandAll = () => {
    setExpandAll(!expandAll);
  }

  const getTableRow = (reportType, row, idx, collapsable, isLast) => {
    if (_.isNil(row)) {
      return null;
    }

    if ((_.isNumber(row.amount) && new Decimal(row.amount).todp(0).equals(0) && _.isNil(comparePeriod)) || (_.isNumber(row.amount) && _.isNumber(row.compareAmount) && new Decimal(row.amount).todp(0).equals(0) && new Decimal(row.compareAmount).todp(0).equals(0) && !_.isEmpty(comparePeriod.endDate))) {
      return;
    }

    return <IncomeStatementDetailRow key={idx} idx={idx} row={row} numRecords={numRecords} hasHeader={hasHeader} compareEndDate={_.get(comparePeriod, 'endDate', null)} orderBy={orderBy} collapsable={collapsable} isLast={isLast} openState={expandAll} groupBy={groupBy} />
  }

  const renderTableBody = () => {
    return (
      <React.Fragment>
        {_.map(sortedTableData, (_data, _idx) => getTableRow(reportType, _data, _idx, true))}
        {dataLoaded && !_.isNil(oobRow) && getTableRow(reportType, oobRow, numRecords - 1, false)}
        {getTableRow(reportType, totalRow, numRecords, false, true)}
      </React.Fragment>
    )
  }

  const handleGroupBy = (event, value) => {
    setGroupBy(value);
  };

  return (
    <React.Fragment>
      <Stack direction={'row'} marginBottom={'1.5rem'} marginTop={'0.25rem'} alignItems={'center'} spacing={'1rem'}>
        <Typography variant={'body1'}>View by:</Typography>
        <StyledTabs value={groupBy} onChange={handleGroupBy} sx={{paddingX: '0.125rem', paddingY: '0.125rem', borderRadius: '0.5rem', border: '1px solid #DADFD8', alignItems: 'center', maxHeight: '2.5rem', minHeight: '2.5rem', backgroundColor: WHITE_100}}>
          <StyledTab label={'Department'} value={'label'} selected={groupBy === 'label'} onClick={() => setGroupBy('label')} sx={{marginX: '0.125rem', maxHeight: '2rem', minHeight: '2rem'}} />
          <StyledTab label={'Account'} value={'account'} selected={groupBy === 'account'} onClick={() => setGroupBy('account')} sx={{marginX: '0.125rem', maxHeight: '2rem', minHeight: '2rem'}} />
        </StyledTabs>
      </Stack>
      <DetailTable
        order={order}
        orderBy={orderBy}
        groupBy={groupBy}
        handleRequestSort={_handleRequestSort}
        renderTableBody={renderTableBody}
        tableHeaders={
          groupBy === 'label'
            ? [{
                label: 'Department',
                id: 'label',
              }]
            : [{
                label: 'Account',
                id: 'label',
              }]
        }
        period={period}
        comparePeriod={comparePeriod}
        generatedPeriod={generatedPeriod}
        hasHeader={hasHeader}
        collapsable={true}
        expandAll={_expandAll}
        reportType={reportType}
      />
    </React.Fragment>
  );
}

IncomeStatementDetailTable.propTypes = {
  tableData: PropTypes.array.isRequired,
  totalRow: PropTypes.object,
  oobRow: PropTypes.object,
  numRecords: PropTypes.number,
  generatedPeriod: PropTypes.shape({
    endDate: PropTypes.string.isRequired,
    periodType: PropTypes.string.isRequired,
  }),
  period: PropTypes.shape({
    endDate: PropTypes.string.isRequired,
    periodType: PropTypes.string.isRequired,
  }).isRequired,
  comparePeriod: PropTypes.shape({
    endDate: PropTypes.string.isRequired,
    periodType: PropTypes.string.isRequired,
  }),
  hasHeader: PropTypes.bool,
  reportType: PropTypes.string.isRequired,
  dataLoaded: PropTypes.bool.isRequired,
};

export default IncomeStatementDetailTable;
