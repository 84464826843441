import React, {useEffect, useState, forwardRef} from 'react'
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import {connect} from 'react-redux';
import Box from '@mui/material/Box';
import {withRequiredAuthInfo} from '@propelauth/react';
import _ from 'lodash';
import {withLogger, LoggerProps} from '../components/LoggingWrapper';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {TableVirtuoso} from 'react-virtuoso';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import {
  FormControlLabel,
  FormGroup, Switch,
  TableSortLabel,
} from '@mui/material';
import {visuallyHidden} from '@mui/utils';
import {useGetAccountsQuery, useGetPresentationItemsQuery} from '../data/api';
import CompanyConfigTable from '../components/CompanyConfigTable';
import CommonPageWrapper from '../components/CommonPageWrapper';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import {GREEN_70} from '../App';
import AppPage from './AppPage';
import {isTruthy, sortRecords} from '../util';

const _augmentAccounts = (accounts, plis) => {
  return _.map(accounts, _account => {
    let _pliName = '-';
    const _fsli = _.find(plis, {id: _account.pliMapping});
    if (!_.isNil(_fsli) && _.toLower(_fsli.name) !== 'n/a') {
      _pliName = _fsli.name;
    }

    return {
      ..._account,
      pliName: _pliName,
    };
  });
}

const _getNonGAAPReportingValue = row => {
  if (_.has(row, 'excludeFromNonGAAP')) {
    if (row.excludeFromNonGAAP === true) {
      return 'Excluded';
    } else if (row.excludeFromNonGAAP === false) {
      return 'Included';
    }
  }
  return '-';
}

const filterActiveOnly = (accounts, activeOnly) => {
  if (activeOnly) {
    return _.filter(accounts, _a => !isTruthy(_a.isinactive));
  } else {
    return accounts;
  }
}

class AccountsMetadataPage extends AppPage {
  getPageTitle = () => 'Chart of Accounts';
  renderBodyContent = () => <AccountsMetadataPageComponent session={this.props.session} accessToken={this.props.accessToken} />;
}

const AccountsMetadataPageComponent = ({session}) => {
  const {currentOrg} = session;
  const [activeOnly, setActiveOnly] = useState(true);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('acctnumber');
  const [augmentedAccounts, setAugmentedAccounts] = useState([]);
  const [sortedAccounts, setSortedAccounts] = useState([]);

  const {data: accounts, isFetching: isAccountsFetching, isSuccess: isAccountsSuccess, refetch: refetchAccounts, isError: isAccountsError} = useGetAccountsQuery();
  const {data: plis, isFetching: isPLIsFetching, isError: isPLIsError} = useGetPresentationItemsQuery();

  useEffect(() => {
    let _augmentedAccounts = [];
    if (!isAccountsFetching && !isAccountsError && !isPLIsFetching && !isPLIsError) {
      _augmentedAccounts = _augmentAccounts(accounts, plis);
      setAugmentedAccounts(_augmentedAccounts);
    }
    const _filteredAccounts = filterActiveOnly(_augmentedAccounts, activeOnly);
    setSortedAccounts(sortRecords(_filteredAccounts, order, orderBy));
  }, [accounts, plis]);

  useEffect(() => {
    refetchAccounts();
  }, [currentOrg]);

  useEffect(() => {
    const _filteredAccounts = filterActiveOnly(augmentedAccounts, activeOnly);
    setSortedAccounts(sortRecords(_filteredAccounts, order, orderBy));
  }, [augmentedAccounts, activeOnly, order, orderBy]);

  const VTCScroller = forwardRef((props, ref) => (
    <TableContainer {...props} ref={ref} />
  ));
  VTCScroller.displayName = 'Scroller';
  const VTCTableBody = forwardRef((props, ref) => <TableBody {...props} ref={ref} />);
  VTCTableBody.displayName = 'TableBody';
  const VTCTableRow = ({item: _item, ...props}) => <TableRow {...props} />;
  VTCTableRow.propTypes = {
    item: PropTypes.any.isRequired,
  };

  const VirtuosoTableComponents = {
    displayName: 'VirtuosoTableComponents',
    Scroller: VTCScroller,
    Table: (props) => (
      <Table {...props} sx={{borderCollapse: 'separate', tableLayout: 'fixed', minHeight: 'auto', height: 'auto'}} />
    ),
    TableHead,
    TableRow: VTCTableRow,
    TableBody: VTCTableBody,
  };

  const _headers = [
    {
      id: 'active',
      label: 'Active?',
      align: 'center',
      sx: {
        width: '6.25rem',
      },
    },
    {
      id: 'acctnumber',
      label: 'Number',
      sx: {
        width: '9rem',
      },
    },
    {
      id: 'accountsearchdisplaynamecopy',
      label: 'Account',
      width: '100%',
    },
    {
      id: 'accttype',
      label: 'Type',
      sx: {
        width: '10rem',
      },
    },
    {
      id: 'pliName',
      label: 'FSLI',
    },
    {
      id: 'nonGAAP',
      label: 'Non GAAP reporting',
      sx: {
        width: '12rem',
      },
    },
  ]

  const handleRequestSort = (property) => () => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  }

  const fixedHeaderContent = () => {
    return (
      <TableRow>
        {_.map(_headers, _header =>
          <TableCell
            key={_header.id}
            sortDirection={orderBy === _header.id ? order : false}
            variant={'head'}
            align={'left'}
            datatestid={`tableHeader-${_header.id}`}
            sx={{
              ..._header.sx,
              borderBottom: 1,
            }}
          >
            <TableSortLabel
              active={orderBy === _header.id}
              direction={orderBy === _header.id ? order : 'asc'}
              onClick={handleRequestSort(_header.id)}>
              <Typography variant={'body2'}>{_header.label}</Typography>
              {orderBy === _header.id
                ? (
                  <Box component={'span'} sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                  )
                : null}
            </TableSortLabel>
          </TableCell>,
        )}
      </TableRow>
    );
  }

  const rowContent = (_index, row) => {
    return (
      <React.Fragment>
        <TableCell align={'center'} datatestid={`tableRow-active-${_index}`}>
          {!isTruthy(row.isinactive) ? <TaskAltIcon /> : <RadioButtonUncheckedIcon />}
        </TableCell>
        <TableCell datatestid={`tableRow-acctnumber-${_index}`}>
          <Typography>{row.acctnumber}</Typography>
        </TableCell>
        <TableCell datatestid={`tableRow-accountsearchdisplaynamecopy-${_index}`}>
          <Typography>{row.inscopeAccountType === 'EXTERNAL' ? row.accountsearchdisplaynamecopy : row.accountName}</Typography>
        </TableCell>
        <TableCell datatestid={`tableRow-accttype-${_index}`}>
          <Typography>{row.accttype}</Typography>
        </TableCell>
        <TableCell datatestid={`tableRow-directFSLI-${_index}`}>
          <Typography>{_.upperFirst(row.pliName)}</Typography>
        </TableCell>
        <TableCell datatestid={`tableRow-reportingStandard-${_index}`}>
          <FormGroup>
            <Typography>{_getNonGAAPReportingValue(row)}</Typography>
          </FormGroup>
        </TableCell>
      </React.Fragment>
    );
  }

  return (
    <CommonPageWrapper>
      <CompanyConfigTable
        hasError={isAccountsError}
        errorMessage={"There was an error loading your data. We're on it!"}
        loading={isAccountsFetching || isPLIsFetching}
        screen={'Chart of Accounts'}
        success={isAccountsSuccess}
        hasData={!_.isEmpty(sortedAccounts)}>
        <Box width={'100%'} justifySelf={'flex-end'} datatestid={'departments-box-activeToggle'} paddingTop={'1.5rem'} paddingRight={'1.5rem'} sx={{backgroundColor: GREEN_70}}>
          <FormGroup>
            <FormControlLabel checked={activeOnly} onChange={() => setActiveOnly(!activeOnly)} control={<Switch />} label={'Show Active only?'} labelPlacement={'start'} />
          </FormGroup>
        </Box>
        <TableVirtuoso
          datatestid={'accounts-box-table'}
          data={sortedAccounts}
          components={VirtuosoTableComponents}
          fixedHeaderContent={fixedHeaderContent}
          itemContent={rowContent}
        />
      </CompanyConfigTable>
    </CommonPageWrapper>
  )
}

AccountsMetadataPageComponent.propTypes = {
  accessToken: PropTypes.string.isRequired,
  session: PropTypes.shape({
    currentOrg: PropTypes.shape({
      orgId: PropTypes.string.isRequired,
    }),
  }),
  logger: LoggerProps,
}

const mapStateToProps = (state) => {
  return {
    session: state.session,
  }
}

export default connect(mapStateToProps)(withRequiredAuthInfo(withLogger(AccountsMetadataPage)));
