import React from 'react'
import {useSelector} from 'react-redux';
import {Stack} from '@mui/material';
import AppPage from './AppPage';
import CommonPageWrapper from '../components/CommonPageWrapper';
import Typography from '@mui/material/Typography';
import {withLDConsumer} from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';

class OverviewPage extends AppPage {
  getPageTitle = () => 'Home';
  renderBodyContent = () => <OverviewPageComponent flags={this.props.flags} />;
}

const OverviewPageComponent = ({flags}) => {
  const {currentOrg} = useSelector((state) => state.session);

  return (
    <CommonPageWrapper>
      <Stack direction={'column'} spacing={2} mt={1.5} flex={1}>
        <Typography variant={'h2'} color={''}>Welcome, {currentOrg.orgName}!</Typography>
        <Typography variant={'body1'}>We’re excited to share this very early version of our product with you.</Typography>
        <Typography variant={'body1'}>As a design partner, your feedback is instrumental in shaping the future of financial reporting.</Typography>
      </Stack>
    </CommonPageWrapper>
  )
}

OverviewPageComponent.propTypes = {
  flags: PropTypes.shape({
    financialReports: PropTypes.bool.isRequired,
    reportWorkspace: PropTypes.bool.isRequired,
  }),
}

export default withLDConsumer()(OverviewPage);
