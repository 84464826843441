import axios from 'axios';

const _exec = async (method, accessToken, orgId, request) => {
  return (await axios.request({
    method,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'x-inscope-org': orgId,
    },
    baseURL: process.env.REACT_APP_BASE_API_URL,
    ...request,
  })).data
}

const _get = (path, {params, accessToken, orgId}) => {
  return _exec(
    'get',
    accessToken,
    orgId,
    {
      url: path,
      params,
    },
  )
}

const _post = async (path, {params, body, accessToken, orgId}) => {
  return _exec(
    'post',
    accessToken,
    orgId,
    {
      url: path,
      data: body,
      params,
    },
  )
}

export const whoAmI = (accessToken, orgId) => {
  return _get('/app/whoAmI', {accessToken, orgId})
}

export const downloadReports = (accessToken, orgId, body, params) => {
  return _post('/app/reports/download', {accessToken, orgId, body, params});
}

export const getDepartments = (accessToken, orgId) => {
  return _get('/app/departments', {accessToken, orgId})
}

export const getFile = (accessToken, orgId, fileId) => {
  return _get(`/app/report/${fileId}/workbook`, {accessToken, orgId});
}

export const getFSLIs = (accessToken, orgId) => {
  return _get('/app/fslis', {accessToken, orgId})
}

export const getReport = (accessToken, orgId, params) => {
  return _get('/app/report', {accessToken, orgId, params})
}

export const getReportingPeriods = (accessToken, orgId) => {
  return _get('/app/reportingPeriods', {accessToken, orgId});
}

export const listReports = (accessToken, orgId, period) => {
  return _get('/app/reports', {accessToken, orgId, params: {period}})
}
