import React, {useEffect, useState} from 'react'
import Stack from '@mui/material/Stack';
import {connect, useDispatch, useSelector} from 'react-redux';
import _ from 'lodash';
import Box from '@mui/material/Box';
import {withRequiredAuthInfo} from '@propelauth/react';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import {useGetReportingPeriodsQuery, useGetReportsListQuery} from '../data/api';
import CommonPageWrapper from '../components/CommonPageWrapper';
import {useFlags, withLDConsumer} from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';
import {withLogger} from '../components/LoggingWrapper';
import AppPage from './AppPage';
import AppBarSelect from '../components/AppBarSelect';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import {setSelectedComparePeriod, setSelectedPeriod} from '../data/periods';
import ConfiguredReport from '../components/financialStatements/ConfiguredReport';
import DownloadReport from '../components/DownloadReport';
import ReportsMarketingLander from '../components/ReportsMarketingLander';
import {REPORT_TYPES} from '../constants';
import {
  setComparePeriod,
  setCurrentPeriod,
  setHasDifference,
  setHasUnpublishedReports,
  setReviewMode,
} from '../data/reports';

const TABS = [
  {
    reportType: 'BALANCE_SHEET',
    ...REPORT_TYPES.BALANCE_SHEET,
  },
  {
    reportType: 'INCOME_STATEMENT_GAAP',
    ...REPORT_TYPES.INCOME_STATEMENT_GAAP,
  },
  {
    reportType: 'INCOME_STATEMENT_NON_GAAP',
    ...REPORT_TYPES.INCOME_STATEMENT_NON_GAAP,
  },
  {
    reportType: 'INDIRECT_CASHFLOW',
    ...REPORT_TYPES.INDIRECT_CASHFLOW,
  },
  {
    reportType: 'DIRECT_CASHFLOW',
    ...REPORT_TYPES.DIRECT_CASHFLOW,
  },
  {
    label: 'SSE (coming soon)',
    reportType: 'SSE',
    disabled: true,
  },
  {
    label: 'SCI (coming soon)',
    reportType: 'SCI',
    disabled: true,
  },
]

const getPeriodPadding = periodType => {
  switch (periodType) {
    case 'quarter':
      return '0rem';
    case 'month':
      return '1rem';
    default:
      return '0rem';
  }
}

const ReportingPeriodSelectWrapper = ({onPeriodChange, onComparePeriodChange, id, startIcon, selectedPeriod, selectedComparePeriod}) => {
  const {aggregateReportingEnabled} = useFlags();
  if (!aggregateReportingEnabled) {
    const {data, isFetching, isError} = useGetReportingPeriodsQuery();
    const _periodData = _.map(_.filter(data, {periodType: 'month'}), _period => ({value: _period.period, label: _period.periodName, paddingLeft: '0rem'}));
    const _comparePeriodData = _.filter(_periodData, _period => _period.value !== selectedPeriod);
    return (
      <Stack direction={'row'} spacing={'0.625rem'}>
        <AppBarSelect onChange={onPeriodChange} id={id} startIcon={startIcon} data={_periodData} loading={isFetching} isError={isError} placeholder={'Period'} selectedPeriod={selectedPeriod} />
        <AppBarSelect onChange={onComparePeriodChange} id={id} startIcon={startIcon} data={_comparePeriodData} loading={isFetching} isError={isError} placeholder={'Compare Period'} selectedPeriod={selectedComparePeriod} optional />
      </Stack>
    );
  } else {
    const {data, isFetching, isError} = useGetReportingPeriodsQuery();
    const _periodData = _.map(
      data,
      _period => {
        return {
          value: _period.period,
          label: _period.periodName,
          startdate: _period.startdate,
          paddingLeft: getPeriodPadding(_period.periodType),
          id: _period.id,
          parentperiodid: _period.parentperiodid,
        }
      });
    const _comparePeriodData = _.filter(_periodData, _period => _period.value !== selectedPeriod);
    return (
      <Stack direction={'row'} spacing={'0.625rem'}>
        <AppBarSelect onChange={onPeriodChange} id={id} startIcon={startIcon} data={_periodData} loading={isFetching} isError={isError} placeholder={'Period'} selectedPeriod={selectedPeriod} />
        <AppBarSelect onChange={onComparePeriodChange} id={id} startIcon={startIcon} data={_comparePeriodData} loading={isFetching} isError={isError} placeholder={'Compare Period'} selectedPeriod={selectedComparePeriod} optional />
      </Stack>
    );
  }
}

ReportingPeriodSelectWrapper.propTypes = {
  onPeriodChange: PropTypes.func.isRequired,
  onComparePeriodChange: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  startIcon: PropTypes.node.isRequired,
  selectedPeriod: PropTypes.string.isRequired,
  selectedComparePeriod: PropTypes.string.isRequired,
}

class ReportsPage extends AppPage {
  constructor (props) {
    super(props);

    this.state = {
      reports: [],
      compareReports: [],
      reportType: 'BALANCE_SHEET',
    }
  }

  setReportType = reportType => {
    this.setState({reportType});
  }

  getPageTitle = () => {
    const {orgConfig} = this.props.session;

    if (orgConfig && !orgConfig.onboardingCompleted) {
      return;
    }

    return 'Financial Statements';
  }

  handlePeriodChange = (period) => {
    this.props.setSelectedPeriod({period});
    this.props.setCurrentPeriod({currentPeriod: period});
    if (this.props.reviewMode) {
      this.props.setComparePeriod({comparePeriod: period});
    }
  };

  handleComparePeriodChange = (period) => {
    if (period !== '') {
      this.props.setReviewMode({reviewMode: false});
    } else {
      this.props.setReviewMode({reviewMode: this.props.accessHelper.isRole(this.props.session.currentOrg.orgId, 'InScope Report Admin')});
    }
    this.props.setSelectedComparePeriod({comparePeriod: period});
    this.props.setComparePeriod({comparePeriod: period});
  };

  renderCustomHeaderContent = () => {
    const {orgConfig} = this.props.session;

    if (orgConfig && !orgConfig.onboardingCompleted) {
      return;
    }

    return (
      <Stack direction={'row'} flex={1} alignItems={'center'} justifyContent={'space-between'} margin={'0.625rem'}>
        <Stack direction={'row'} alignItems={'center'} spacing={'0.625rem'}>
          <Box width={'0.0625rem'} height={'1.875rem'} bgcolor={'#D9D9D9'}/>
          <ReportingPeriodSelectWrapper
            onPeriodChange={this.handlePeriodChange}
            onComparePeriodChange={this.handleComparePeriodChange}
            id={'periodSelect'}
            startIcon={<CalendarTodayIcon />}
            selectedPeriod={this.props.periods.selectedPeriod}
            selectedComparePeriod={this.props.periods.selectedComparePeriod}
          />
          <AppBarSelect
            onChange={this.setReportType}
            id={'reportSelector'}
            startIcon={<InsertDriveFileOutlinedIcon />}
            data={_.map(_.filter(TABS, o => !_.has(o, 'disabled') || o.disabled === false), _tab => ({value: _tab.reportType, label: _tab.title}))}
            loading={false}
            isError={false}
            placeholder={'Report'}
            minWidth={'17.837rem'}
          />
        </Stack>
        <Box>
          <DownloadReport
            visible={!_.isEmpty(this.state.reports) && (!_.has(this.props.session.currentOrg, 'org_metadata.hideDownload') || this.props.session.currentOrg.org_metadata.hideDownload === false)}
            reports={this.state.reports}
          />
        </Box>
      </Stack>
    )
  };

  renderBodyContent = () => {
    return (
      <ReportsPageComponent reportType={this.state.reportType} setReports={(_reports) => this.setState({reports: _reports})} />
    )
  }
}

const ReportsPageComponent = ({reportType, setReports}) => {
  const {aggregateReportingEnabled} = useFlags();
  const {data: periods, isFetching: reportingPeriodsIsFetching} = useGetReportingPeriodsQuery();
  const dispatch = useDispatch();
  const {currentOrg, orgConfig, isAuthenticated} = useSelector((state) => state.session);
  const {selectedPeriod: period, selectedComparePeriod: comparePeriod} = useSelector((state) => state.periods);
  const {reviewMode} = useSelector((state) => state.reports);
  const [skip, setSkip] = useState(true);
  const {data, isFetching, refetch, isError, isSuccess} = useGetReportsListQuery({period, comparePeriod, reviewMode}, {skip: skip || _.isEmpty(period)});
  const [reports, setLocalReports] = useState([]);

  useEffect(() => {
    if (!aggregateReportingEnabled) {
      const _periods = _.filter(periods, {periodType: 'month'});
      if (!_.isNil(_periods) && !_.isEmpty(_periods)) {
        dispatch(setSelectedPeriod({period: _periods[0].period}));
      }
    } else {
      if (!_.isNil(periods) && !_.isEmpty(periods)) {
        dispatch(setSelectedPeriod({period: periods[0].period}));
      }
    }
  }, [periods]);

  useEffect(() => {
    let _reports;
    if (!aggregateReportingEnabled) {
      _reports = _.get(data, 'reports', []);
    } else {
      const _allReports = _.get(data, 'reports', []);
      _reports = _.defaultTo(_.find(_allReports, {period}), {data: []}).data;
    }
    console.log('reports loaded', {_reports, data, period});
    setLocalReports(_reports);
    setReports(_reports);
    dispatch(setHasDifference({hasDifference: reviewMode && _.some(_reports, 'hasDifference')}));
    dispatch(setHasUnpublishedReports({hasUnpublishedReports: reviewMode && _.get(data, 'hasUnpublishedReports', false)}));
  }, [data]);

  useEffect(() => {
    setSkip(_.isNil(period) || _.isEmpty(period) || _.isNil(currentOrg) || _.isEmpty(currentOrg) || !isAuthenticated);
  }, [period, currentOrg, isAuthenticated]);

  useEffect(() => {
    if (isSuccess) {
      refetch();
    }
  }, []);

  if (orgConfig && !orgConfig.onboardingCompleted) {
    return <ReportsMarketingLander orgName={currentOrg.orgName} />;
  }

  return (
    <CommonPageWrapper>
      <Stack direction={'column'} flex={1} justifyContent={'center'} maxWidth={'67rem'}>
        {!reportingPeriodsIsFetching && (
          <Stack direction={'column'} flex={1}>
            {_.map(TABS, _tab => {
              return <ConfiguredReport
                key={_tab.reportType}
                reportType={_tab.reportType}
                visible={_tab.reportType === reportType}
                loading={isFetching}
                hasError={isError}
                report={_.find(reports, _report => _report.record.reportType === _tab.reportType)}
                title={_tab.title}
                depthCorrection={_tab.depthCorrection}
                period={period}
                comparePeriod={comparePeriod}
                reviewMode={reviewMode}
              />
            })}
          </Stack>
        )}
      </Stack>
    </CommonPageWrapper>
  )
}

ReportsPageComponent.propTypes = {
  reportType: PropTypes.string.isRequired,
  setReports: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    periods: state.periods,
    session: state.session,
    reports: state.reports,
  }
}

export default withLDConsumer()(connect(mapStateToProps, {setSelectedPeriod, setSelectedComparePeriod, setCurrentPeriod, setComparePeriod, setReviewMode})(withRequiredAuthInfo(withLogger(ReportsPage))));
