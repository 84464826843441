import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {IconButton, Modal, Stack} from '@mui/material';
import {APP_HEADER_HEIGHT, BLACK_100, GREY_70, WHITE_100} from '../../App';
import CloseIcon from '@mui/icons-material/Close';
import Skeleton from '@mui/material/Skeleton';
import numeral from 'numeral';
import {sortRecords} from '../../util';
import CommonStatementDetailTable from '../CommonStatementDetailTable';
import IncomeStatementDetailTable from '../IncomeStatementDetailTable';
import {useGetReportDetailsV2Query} from '../../data/api';
import Decimal from 'decimal.js-light';
import {useSelector} from 'react-redux';
import AlertTriangle from '../../assets/alert-triangle.svg';

const checkRoundingOOB = (data, total, isCompare) => {
  if (_.isNil(total)) {
    return null;
  }
  const _dataTotal = _.sumBy(data, o => new Decimal(isCompare ? o.compareAmount : o.amount).todp(0).toNumber());
  const _dataTotalString = numeral(_dataTotal).format('0');
  const _totalString = numeral(total).format('0');

  if (_dataTotalString === _totalString) {
    return null;
  }

  const _diff = new Decimal(_totalString).minus(_dataTotal).toNumber();
  if (_diff === 0) {
    return null;
  }
  return _diff;
}

const ReportDetailsComponentModal = ({pli, onClose, reportType, open, period, comparePeriod, meta, currentFileId, compareFileId}) => {
  const [reportData, setReportData] = useState(_.times(6, () => ({
    label: <Skeleton variant={'rounded'} width={_.random(110, 150, false)} />,
    account: <Skeleton variant={'rounded'} width={_.random(130, 200, false)} />,
    amount: <Skeleton variant={'rounded'} width={_.random(50, 65, false)} />,
    compareAmount: <Skeleton variant={'rounded'} width={_.random(50, 65, false)} />,
  })));
  const [oobRow, setOobRow] = useState(null);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const {reviewMode} = useSelector((state) => state.reports);
  const {reportingPeriodMap} = useSelector(state => state.periods);

  if (_.isNil(pli)) {
    return;
  }

  const _ids = {};
  let _hasChildren = false;
  if (!_.isNil(pli) && !_.isEmpty(pli)) {
    if (pli.children.length !== 0) {
      _ids.pliIds = _.map(pli.children, 'id');
      _hasChildren = true;
    } else {
      _ids.pliId = pli.id;
    }
  }
  const {data, isFetching, isError, error, refetch} = useGetReportDetailsV2Query({reportType, ..._ids, period: period.period, comparePeriod: comparePeriod?.period, reviewMode, currentFileId, compareFileId}, {refetchOnMountOrArgChange: true, skip: _.isNil(pli) || _.isNil(pli.id)});

  useEffect(() => {
    if (isFetching) {
      setDataLoaded(false);
      setShowAlert(false);
      setOobRow(null);
      setReportData(_.times(6, () => ({
        label: <Skeleton variant={'rounded'} width={_.random(110, 150, false)} />,
        account: <Skeleton variant={'rounded'} width={_.random(130, 200, false)} />,
        amount: <Skeleton variant={'rounded'} width={_.random(50, 65, false)} />,
        compareAmount: <Skeleton variant={'rounded'} width={_.random(50, 65, false)} />,
      })));
    }
  }, [isFetching]);

  useEffect(() => {
    setDataLoaded(false);
    setOobRow(null);
    setReportData(_.times(6, () => ({
      label: <Skeleton variant={'rounded'} width={_.random(110, 150, false)} />,
      account: <Skeleton variant={'rounded'} width={_.random(130, 200, false)} />,
      amount: <Skeleton variant={'rounded'} width={_.random(50, 65, false)} />,
      compareAmount: <Skeleton variant={'rounded'} width={_.random(50, 65, false)} />,
    })));
    setOobRow(null);
  }, [open]);

  useEffect(() => {
    if (isError) {
      console.error('Error loading report data', {error});
      setShowAlert(true);
    }
  }, [isError, error]);

  useEffect(() => {
    if (!isFetching && !isError) {
      let _oobRow;
      let _tableData = !_.isNil(data) && _.isArray(data) ? _.clone(data) : [];
      _tableData = _.map(_tableData, _record => {
        if (!_hasChildren) {
          return {
            ..._record,
            config: pli.config,
          };
        } else {
          const _pli = _.find(pli.children, {id: _record.pli_id});
          return {
            ..._record,
            config: !_.isNil(_pli) ? _pli.config : null,
          };
        }
      });
      let _dataOobAmount;
      let _compareDataOobAmount;
      if (!_.includes(['INCOME_STATEMENT_GAAP', 'INCOME_STATEMENT_NON_GAAP'], reportType)) {
        // Reduce the lines
        const _reducedResults = _.values(_.reduce(_tableData, (result, _o) => {
          const _total = new Decimal(_o.total).times(_hasChildren && _o.config.invertCalculationAmount ? -1 : 1).toNumber();
          const _amount = new Decimal(_o.amount).times(_hasChildren && _o.config.invertCalculationAmount ? -1 : 1).toNumber();
          const _compareTotal = !_.isNil(_o.compareTotal) ? new Decimal(_o.compareTotal).times(_hasChildren && _o.config.invertCalculationAmount ? -1 : 1).toNumber() : 0;
          const _compareAmount = !_.isNil(_o.compareAmount) ? new Decimal(_o.compareAmount).times(_hasChildren && _o.config.invertCalculationAmount ? -1 : 1).toNumber() : 0;
          result[`${_o.accountNumber} - ${_o.accountName}`] = {
            ..._o,
            total: _.isNil(result[`${_o.accountNumber} - ${_o.accountName}`]) ? _total : result[`${_o.accountNumber} - ${_o.accountName}`].total + _total,
            amount: _.isNil(result[`${_o.accountNumber} - ${_o.accountName}`]) ? _amount : result[`${_o.accountNumber} - ${_o.accountName}`].amount + _amount,
            compareTotal: _.isNil(result[`${_o.accountNumber} - ${_o.accountName}`]) ? _compareTotal : result[`${_o.accountNumber} - ${_o.accountName}`].compareTotal + _compareTotal,
            compareAmount: _.isNil(result[`${_o.accountNumber} - ${_o.accountName}`]) ? _compareAmount : result[`${_o.accountNumber} - ${_o.accountName}`].compareAmount + _compareAmount,
          }
          return result;
        }, []));

        _dataOobAmount = checkRoundingOOB(_reducedResults, pli.amount, false, _hasChildren);
        _compareDataOobAmount = checkRoundingOOB(_reducedResults, pli.compareAmount, true, _hasChildren);
        _tableData = sortRecords(_reducedResults, 'asc', 'accountNumber');
      } else {
        _dataOobAmount = checkRoundingOOB(_tableData, pli.amount, false, _hasChildren);
        _compareDataOobAmount = checkRoundingOOB(_tableData, pli.compareAmount, true, _hasChildren);
        _tableData = sortRecords(_tableData, 'asc', 'accountNumber');
      }

      if (!_.isNil(_dataOobAmount) || !_.isNil(_compareDataOobAmount)) {
        _oobRow = {
          label: 'Difference due to rounding',
          amount: _dataOobAmount,
          compareAmount: _compareDataOobAmount,
        };
      }
      setOobRow(_oobRow);
      setReportData(_tableData);
      setDataLoaded(true);
    }
  }, [data, isFetching, isError]);

  const _renderAlert = () => {
    if (showAlert) {
      return (
        <Stack direction={'row'} alignItems={'center'} paddingX={'2rem'} spacing={'1rem'} minHeight={'3rem'} flex={1} sx={{background: 'linear-gradient(0deg, #FFEEC3 0%, #FFEEC3 100%), #FCB64C;'}}>
          <img src={AlertTriangle} alt={''}/>
          <Stack direction={'row'}>
            <Typography fontSize={'0.875rem'} fontWeight={'400'} lineHeight={'140%'} color={'#000'}>An error occurred while trying to load the data.&nbsp;</Typography>
            <Typography fontSize={'0.875rem'} fontWeight={'400'} lineHeight={'140%'} color={'#000'} sx={{textDecoration: 'underline', cursor: 'pointer'}} onClick={refetch}>Try again?</Typography>
          </Stack>
        </Stack>
      )
    }
  }

  const totalRow = {
    label: 'Total',
    account: 'Total',
    amount: pli.amount,
    compareAmount: pli.compareAmount,
  }

  let _numRecords = reportData.length;
  if (!_.isNil(oobRow)) {
    _numRecords += 1;
  }

  let _generatedPeriod;
  if (!_.isNil(meta) && _.has(meta, 'generatedPeriod')) {
    _generatedPeriod = reportingPeriodMap[meta.generatedPeriod];
  }

  return (
    <Modal
      open={open}
      onClose={onClose}>
      <Box position={'absolute'} sx={{left: '50%', top: `calc(${APP_HEADER_HEIGHT} + 1.75rem)`, transform: 'translate(-50%, 0)'}}>
        <Box width={'67rem'}>
          <Stack direction={'column'} height={'100%'}>
            <Stack direction={'row'} justifyContent={'space-between'} minHeight={'4.25rem'} maxHeight={'4.25rem'} paddingX={'1.875rem'} alignItems={'center'} bgcolor={WHITE_100} borderRadius={'0.5rem 0.5rem 0 0'}>
              <Typography fontSize={'1.25rem'} fontStyle={'normal'} fontWeight={'700'} lineHeight={'130%'} letterSpacing={'0.025rem'} color={BLACK_100}>{pli.name}</Typography>
              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </Stack>
            {_renderAlert()}
            <Box width={'100%'} sx={{backgroundColor: GREY_70, borderRadius: '0 0 0.5rem 0.5rem'}} paddingX={'2rem'} paddingY={'1.25rem'}>
              {!_.includes(['INCOME_STATEMENT_GAAP', 'INCOME_STATEMENT_NON_GAAP'], reportType) && (
                <CommonStatementDetailTable isError={isError} tableData={reportData} totalRow={totalRow} oobRow={oobRow} numRecords={_numRecords} period={period} comparePeriod={comparePeriod} generatedPeriod={_generatedPeriod} reportType={reportType} invertDisplayAmount={!_.isNil(pli) && !_.isEmpty(pli) ? pli.config.invertDisplayAmount : false} />
              )}
              {_.includes(['INCOME_STATEMENT_GAAP', 'INCOME_STATEMENT_NON_GAAP'], reportType) && (
                <IncomeStatementDetailTable isError={isError} tableData={reportData} totalRow={totalRow} oobRow={oobRow} numRecords={_numRecords} period={period} comparePeriod={comparePeriod} generatedPeriod={_generatedPeriod} reportType={reportType} invertDisplayAmount={!_.isNil(pli) && !_.isEmpty(pli) ? pli.config.invertDisplayAmount : false} dataLoaded={dataLoaded} />
              )}
            </Box>
          </Stack>
        </Box>
      </Box>
    </Modal>
  )
}

ReportDetailsComponentModal.propTypes = {
  pli: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    amount: PropTypes.number,
    children: PropTypes.array,
    compareAmount: PropTypes.number,
    config: PropTypes.shape({
      invertDisplayAmount: PropTypes.bool,
    }),
  }),
  reportType: PropTypes.string,
  open: PropTypes.bool,
  period: PropTypes.shape({
    period: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    periodType: PropTypes.string.isRequired,
  }).isRequired,
  comparePeriod: PropTypes.shape({
    period: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    periodType: PropTypes.string.isRequired,
  }),
  meta: PropTypes.shape({
    generatedPeriod: PropTypes.string,
  }),
  onClose: PropTypes.func,
  currentFileId: PropTypes.string.isRequired,
  compareFileId: PropTypes.string,
}

export default ReportDetailsComponentModal;
