import {createSlice} from '@reduxjs/toolkit';
import _ from 'lodash';

const INITIAL_STATE = {
  autoApproveReports: false,
  reviewMode: false,
  currentPeriod: '',
  comparePeriod: '',
  hasDifference: false,
  hasUnpublishedReports: false,
}

export const reportsSlice = createSlice({
  name: 'periods',
  initialState: INITIAL_STATE,
  reducers: {
    setAutoApproveReports: (state, action) => {
      state.autoApproveReports = action.payload.autoApproveReports;
    },
    setReviewMode: (state, action) => {
      state.reviewMode = action.payload.reviewMode;
    },
    setCurrentPeriod: (state, action) => {
      state.currentPeriod = action.payload.currentPeriod;
    },
    setComparePeriod: (state, action) => {
      state.comparePeriod = _.get(action, 'payload.comparePeriod', '');
    },
    setHasDifference: (state, action) => {
      state.hasDifference = action.payload.hasDifference;
    },
    setHasUnpublishedReports: (state, action) => {
      state.hasUnpublishedReports = action.payload.hasUnpublishedReports;
    },
    reset: () => INITIAL_STATE,
  },
})

// Action creators are generated for each case reducer function
export const {reset, setHasUnpublishedReports, setReviewMode, setAutoApproveReports, setCurrentPeriod, setComparePeriod, setHasDifference} = reportsSlice.actions;

export default reportsSlice.reducer;
