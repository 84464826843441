import {TiptapCollabProviderWebsocket} from '@hocuspocus/provider';
let socket = null;
export const initCollabSocket = () => {
  if (!socket || socket.status === 'disconnected') {
    socket = new TiptapCollabProviderWebsocket({
      appId: process.env.REACT_APP_TIPTAP_APP_ID,
    });
  }
  return socket;
};

export const getCollabSocket = () => socket;
