import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';
import PropTypes from 'prop-types';
import Error from '../assets/undraw_fixing_bugs_w7gi.svg';

const ErrorComponent = ({hasError = true, message}) => {
  if (hasError) {
    return (
      <Box
        datatestid={'error-box'}
        sx={styles.errorBox}>
        <img src={Error} alt={''} height={150} />
        <Typography marginTop={'1em'}>{message}</Typography>
      </Box>
    );
  }
}

ErrorComponent.propTypes = {
  hasError: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
}

const styles = {
  errorBox: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    typography: 'body1',
    justifyContent: 'center',
    alignItems: 'center',
    height: 300,
  },
}

export default ErrorComponent;
