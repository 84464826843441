import React, {useEffect, useState} from 'react';
import _ from 'lodash';
import {renderAmount, sortRecords} from '../util';
import {BLACK_100, WHITE_100} from '../App';
import TableRow from '@mui/material/TableRow';
import TableCell from './TableCell';
import {Stack} from '@mui/material';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import DetailTable from './DetailTable';
import Decimal from 'decimal.js-light';
import {useSelector} from 'react-redux';

const ACCOUNT_HEADERS = [
  {
    label: 'Account',
    id: 'label',
  },
];

const CommonDetailRow = ({idx, row, numRecords, hasHeader, compareEndDate, invertDisplayAmount}) => {
  const _bgColor = idx % 2 === 0 ? '#EAEAEA' : WHITE_100;
  const _cellStyle = idx === numRecords && hasHeader ? {border: 0, borderTopWidth: 1, borderColor: BLACK_100} : {border: 0};
  const {reviewMode, hasUnpublishedReports} = useSelector((state) => state.reports);

  return (
    <TableRow key={idx} sx={{backgroundColor: _bgColor, height: '2.25rem', borderWidth: 0}}>
      <TableCell sx={{border: 0}}><Typography>{row.label}</Typography></TableCell>
      <TableCell sx={_cellStyle}>
        <Stack direction={'row'} justifyContent={'space-between'}>
          <Typography>{(idx === 0 || idx === numRecords) && _.isNumber(row.amount) ? '$' : ''}</Typography>
          <Typography>{renderAmount(row.amount, invertDisplayAmount)}</Typography>
        </Stack>
      </TableCell>
      {((!reviewMode && !_.isEmpty(compareEndDate)) || (reviewMode && hasUnpublishedReports)) && (
        <TableCell sx={_cellStyle}>
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Typography>{(idx === 0 || idx === numRecords) && _.isNumber(row.compareAmount) ? '$' : ''}</Typography>
            <Typography>{renderAmount(row.compareAmount, invertDisplayAmount)}</Typography>
          </Stack>
        </TableCell>
      )}
    </TableRow>
  )
}

CommonDetailRow.propTypes = {
  idx: PropTypes.number,
  row: PropTypes.shape({
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    amount: PropTypes.oneOfType([PropTypes.number, PropTypes.object]).isRequired,
    compareAmount: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  }),
  numRecords: PropTypes.number,
  hasHeader: PropTypes.bool,
  compareEndDate: PropTypes.string,
  invertDisplayAmount: PropTypes.bool,
}

const CommonStatementDetailTable = ({tableData, totalRow, oobRow, numRecords, period, comparePeriod, generatedPeriod, hasHeader = true, reportType, invertDisplayAmount}) => {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('label');
  const [sortedTableData, setSortedTableData] = useState([]);

  useEffect(() => {
    setSortedTableData(sortRecords(tableData, order, orderBy, orderBy === 'label' ? 'account' : 'label'));
  }, [tableData, order, orderBy])

  const _handleRequestSort = (property) => () => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  }

  const getTableRow = (reportType, row, idx) => {
    if (_.isNil(row)) {
      return null;
    }

    if ((_.isNumber(row.amount) && new Decimal(row.amount).todp(0).equals(0) && _.isNil(comparePeriod)) || (_.isNumber(row.amount) && _.isNumber(row.compareAmount) && new Decimal(row.amount).todp(0).equals(0) && new Decimal(row.compareAmount).todp(0).equals(0) && !_.isEmpty(comparePeriod.endDate))) {
      return;
    }

    return <CommonDetailRow key={idx} idx={idx} row={row} numRecords={numRecords} hasHeader={hasHeader} compareEndDate={_.get(comparePeriod, 'endDate', null)} invertDisplayAmount={invertDisplayAmount} />
  }

  const renderTableBody = () => {
    return (
      <React.Fragment>
        {_.map(sortedTableData, (_data, _idx) => getTableRow(reportType, _data, _idx, numRecords, hasHeader))}
        {!_.isNil(oobRow) && getTableRow(reportType, oobRow, numRecords - 1, numRecords, hasHeader)}
        {getTableRow(reportType, totalRow, numRecords, numRecords, hasHeader)}
      </React.Fragment>
    )
  }

  return <DetailTable
    order={order}
    orderBy={orderBy}
    handleRequestSort={_handleRequestSort}
    renderTableBody={renderTableBody}
    tableHeaders={ACCOUNT_HEADERS}
    period={period}
    comparePeriod={comparePeriod}
    generatedPeriod={generatedPeriod}
    hasHeader={hasHeader}
    collapsable={false}
    reportType={reportType}
  />
}

CommonStatementDetailTable.propTypes = {
  tableData: PropTypes.array.isRequired,
  totalRow: PropTypes.object,
  oobRow: PropTypes.object,
  numRecords: PropTypes.number,
  generatedPeriod: PropTypes.shape({
    endDate: PropTypes.string.isRequired,
    periodType: PropTypes.string.isRequired,
  }),
  period: PropTypes.shape({
    endDate: PropTypes.string.isRequired,
    periodType: PropTypes.string.isRequired,
  }).isRequired,
  comparePeriod: PropTypes.shape({
    endDate: PropTypes.string.isRequired,
    periodType: PropTypes.string.isRequired,
  }),
  hasHeader: PropTypes.bool,
  reportType: PropTypes.string.isRequired,
  invertDisplayAmount: PropTypes.bool,
};

export default CommonStatementDetailTable;
