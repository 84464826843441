import React, {Component} from 'react';
import {useLocation} from 'react-router-dom';
import {Box, Stack, Typography} from '@mui/material';
import {useSelector} from 'react-redux';
import {usePublishReportsMutation} from '../data/api';
import LoadingButton from '@mui/lab/LoadingButton';
import {APP_HEADER_HEIGHT, MAX_PAGE_CONTENT_WIDTH, GREEN_95, GREY_80} from '../App';

const ReviewModeStatusBar = () => {
  const {pathname} = useLocation();
  const {autoApproveReports, reviewMode, hasDifference, hasUnpublishedReports} = useSelector(state => state.reports);
  const [trigger, mutationState] = usePublishReportsMutation();

  if (!reviewMode || pathname !== '/reports') {
    return;
  }

  if (hasUnpublishedReports && hasDifference) {
    return (
      <Stack direction={'row'} minHeight={'3.5rem'} display={'flex'} flex={1} bgcolor={'#FFF1CC'} alignItems={'center'} px={'1.75rem'} justifyContent={'space-between'} >
        <Typography fontSize={'0.875rem'} fontWeight={400} lineHeight={'1.25rem'}>(Review mode: on) A change was made that affects the reports in this period. Please review and publish those changes if applicable.</Typography>
        <LoadingButton variant={'outlined'} sx={{borderColor: GREEN_95, height: '2rem'}} onClick={trigger} loading={mutationState.isLoading}>Publish changes</LoadingButton>
      </Stack>
    )
  } else if (!autoApproveReports && hasUnpublishedReports) {
    return (
      <Box minHeight={'3.5rem'} display={'flex'} flex={1} bgcolor={GREY_80} alignItems={'center'} px={'1.75rem'} >
        <Typography fontSize={'0.875rem'} fontWeight={400} lineHeight={'1.25rem'}>(Review mode: on) Reports for this period are current, no changes have been made.</Typography>
      </Box>
    )
  } else {
    return (
      <Box minHeight={'3.5rem'} display={'flex'} flex={1} bgcolor={GREY_80} alignItems={'center'} px={'1.75rem'} >
        <Typography fontSize={'0.875rem'} fontWeight={400} lineHeight={'1.25rem'}>(Review mode: on) Reports for this period are current. Note: Report changes for this org are auto-published and don’t have a review state in app.</Typography>
      </Box>
    )
  }
}

class AppPage extends Component {
  getPageTitle = () => 'Implement Me';

  renderHeaderContent = () => {
    return (
      <Box display={'flex'} justifyContent={'center'} width={'100%'} px={7.75}>
        <Box maxWidth={MAX_PAGE_CONTENT_WIDTH} width={'100%'}>
          <Stack direction="row" alignItems="center" spacing={2} pt={5.25} pb={1}>
            <Typography variant="h2" color="#043B34">
              {this.getPageTitle()}
            </Typography>

            {this.renderCustomHeaderContent()}
          </Stack>
        </Box>
      </Box>
    );
  };

  // Override this method in child components to add custom header content
  renderCustomHeaderContent = () => {
    return null;
  };

  renderBodyContent = () => {
    return (
      <Typography type="body1" color="inherit" style={{flex: 1}}>Implement Me</Typography>
    )
  }

  render () {
    return (
      <Stack mt={APP_HEADER_HEIGHT} direction={'column'} width={'100%'}>
        <ReviewModeStatusBar />
        {this.renderHeaderContent()}

        <Box display={'flex'} justifyContent={'center'} height={'100%'} overflow={'scroll'} px={7.75}>
          <Box maxWidth={MAX_PAGE_CONTENT_WIDTH} width={'100%'}>
            {this.renderBodyContent()}
          </Box>
        </Box>
      </Stack>
    );
  }
}

export default AppPage;
