import React, {useState} from 'react';
import {Link, useLocation} from 'react-router-dom';

// MUI
import {
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Popover,
  Typography,
} from '@mui/material';
import Tooltip, {tooltipClasses} from '@mui/material/Tooltip';
import {styled} from '@mui/material/styles';

// Icons
import {ReactComponent as HomeIcon} from '../assets/home.svg';
import {ReactComponent as DollarIcon} from '../assets/dollar-sign-nav.svg';
import {ReactComponent as FileIcon} from '../assets/file.svg';
import {ReactComponent as BookIcon} from '../assets/book.svg';
import {ReactComponent as BookOpenIcon} from '../assets/book-open.svg';
import {ReactComponent as GridIcon} from '../assets/grid.svg';
import {ReactComponent as GearIcon} from '../assets/gear.svg';
import {ReactComponent as MoreIcon} from '../assets/more-horizontal.svg';

// Utils
import PropTypes from 'prop-types';
import {APP_HEADER_HEIGHT, GREEN_NAV, BLACK_100} from '../App';
import {useHostedPageUrls} from '@propelauth/react';
import {withLDConsumer} from 'launchdarkly-react-client-sdk';

const NavTooltip = styled(({className, ...props}) => (
  <Tooltip {...props} classes={{popper: className}} />
))(({theme}) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    color: '#fff',
    backgroundColor: '#054A41',
    boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.10)',
    borderRadius: '4px',
    fontSize: 14,
    minWidth: 75,
    textAlign: 'center',
    fontWeight: 'light',
    padding: '8px',
  },
}));

const MenuItem = ({page, isCurrentPage}) => {
  return (
    <NavTooltip key={page.title} title={page.title} placement='right'>
      <Box mt={1} sx={page.title === 'Settings' ? {marginTop: 'auto'} : {}}>
        <ListItem
          disablePadding
          key={page.title}
          sx={{
            width: 'auto',
            height: '60px',
            my: 1,
            mx: 2.6,
          }}
        >
          <ListItemButton
            component={Link}
            to={page.path}
            sx={{
              height: '44px',
              width: '44px',
              backgroundColor: isCurrentPage(page) ? GREEN_NAV : '#fff',
              borderRadius: '8px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              '&:hover': {
                color: '#054A41',
                backgroundColor: isCurrentPage(page) ? GREEN_NAV : '#EBF0EF',
                borderRadius: '8px',
              },
            }}
          >
            <ListItemIcon sx={{minWidth: 20}}>
              <page.icon
                width={20}
                height={20}
                style={{
                  color: isCurrentPage(page) ? '#fff' : GREEN_NAV,
                  fill: 'none',
                  stroke: 'currentColor',
                }}
              />
            </ListItemIcon>
          </ListItemButton>
        </ListItem>
      </Box>
    </NavTooltip>
  );
};

MenuItem.propTypes = {
  page: PropTypes.shape({
    title: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    icon: PropTypes.elementType.isRequired,
  }).isRequired,
  isCurrentPage: PropTypes.func.isRequired,
};

const MoreMenuItem = ({secondaryPages, isCurrentPage}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const isAnySecondaryPageCurrent = () => {
    return secondaryPages.some(page => isCurrentPage(page));
  };

  return (
    <>
      <NavTooltip title="More" placement="right">
        <Box mt={1}>
          <ListItem
            disablePadding
            sx={{
              width: 'auto',
              height: '60px',
              my: 1,
              mx: 2.6,
            }}
          >
            <ListItemButton
              onClick={handleClick}
              sx={{
                height: '44px',
                width: '44px',
                minWidth: '44px',
                backgroundColor: isAnySecondaryPageCurrent() ? GREEN_NAV : '#fff',
                borderRadius: '8px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                '&:hover': {
                  color: '#054A41',
                  backgroundColor: isAnySecondaryPageCurrent() ? GREEN_NAV : '#EBF0EF',
                  borderRadius: '8px',
                },
              }}
            >
              <ListItemIcon sx={{minWidth: 20}}>
                <MoreIcon
                  width={20}
                  height={20}
                  style={{
                    color: isAnySecondaryPageCurrent() ? '#fff' : GREEN_NAV,
                    fill: 'none',
                    stroke: 'currentColor',
                  }}
                />
              </ListItemIcon>
            </ListItemButton>
          </ListItem>
        </Box>
      </NavTooltip>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        PaperProps={{
          sx: {
            boxShadow: '0px 2px 12px 2px rgba(15, 30, 36, 0.12)',
            width: '220px',
            borderRadius: '4px',
            marginLeft: 3,
          },
        }}
      >
        <Box p={2} pb={1} pr={1}>
          <Typography pb={1} variant='body2' fontWeight='bold'>More</Typography>

          {secondaryPages.map((page) => (
            <ListItem
              disablePadding
              key={page.title}
              sx={{
                height: '60px',
              }}
            >
              <ListItemButton
                component={Link}
                to={page.path}
                onClick={handleClose}
                sx={{
                  padding: 0,
                  height: '44px',
                  borderRadius: '8px',
                  display: 'flex',
                  flexDirection: 'row',
                  backgroundColor: isCurrentPage(page) ? GREEN_NAV : '#fff',
                  '&:hover': {
                    color: '#054A41',
                    backgroundColor: isCurrentPage(page) ? GREEN_NAV : '#EBF0EF',
                    borderRadius: '8px',
                  },
                }}
              >
                <ListItemIcon
                  sx={{
                    height: '44px',
                    width: '44px',
                    minWidth: '44px',
                    borderRadius: '8px',
                    backgroundColor: isCurrentPage(page) ? GREEN_NAV : '#EBF0EF',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <page.icon
                    width={20}
                    height={20}
                    style={{
                      color: isCurrentPage(page) ? '#fff' : GREEN_NAV,
                      fill: 'none',
                      stroke: 'currentColor',
                    }}
                  />
                </ListItemIcon>
                <Typography ml={2} variant="body2" color={isCurrentPage(page) ? '#fff' : BLACK_100}>
                  {page.title}
                </Typography>
              </ListItemButton>
            </ListItem>
          ))}
        </Box>
      </Popover>
    </>
  );
};

MoreMenuItem.propTypes = {
  secondaryPages: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
      icon: PropTypes.elementType.isRequired,
    }),
  ).isRequired,
  isCurrentPage: PropTypes.func.isRequired,
};

export const MENU_BAR_WIDTH = 92;

const MenuBarV2 = ({flags}) => {
  const {pathname} = useLocation();
  const {getAccountPageUrl} = useHostedPageUrls();

  const primaryPages = [
    {title: 'Home', path: '/', enabled: true, icon: HomeIcon},
    {title: 'Statements', path: '/reports', enabled: flags.financialReports, icon: DollarIcon},
    {title: 'Documents', path: '/documents', enabled: flags.reportWorkspace, icon: FileIcon},
  ]

  const secondaryPages = [
    {title: 'FSLI', path: '/fslis', enabled: true, icon: BookOpenIcon},
    {title: 'Chart of Accounts', path: '/chartofaccounts', enabled: true, icon: BookIcon},
    {title: 'Departments', path: '/departments', enabled: true, icon: GridIcon},
  ];

  const settingsPage = {title: 'Settings', path: getAccountPageUrl(), enabled: true, icon: GearIcon};

  const isCurrentPage = (page) => {
    return page.path === pathname;
  };

  return (
    <Drawer
      sx={{
        width: MENU_BAR_WIDTH,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          mt: APP_HEADER_HEIGHT,
          backgroundColor: '#fff',
          width: MENU_BAR_WIDTH,
          borderRadius: '0px 8px 8px 0px',
          borderRight: '1px solid #DDE0DE',
          boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.15)',
          height: `calc(100% - ${APP_HEADER_HEIGHT})`,
        },
      }}
      variant='permanent'
      anchor='left'
    >
      <Box sx={{display: 'flex', flexDirection: 'column', height: '100%'}}>
        <List sx={{flexGrow: 1, overflowY: 'auto'}}>
          {primaryPages.filter((p) => p.enabled).map((page, index) => (
            <React.Fragment key={page.title}>
              <MenuItem
                page={page}
                isCurrentPage={isCurrentPage}
              />
            </React.Fragment>
          ))}
        </List>

        <Box mt='auto' pb={2}>
          <MoreMenuItem
            secondaryPages={secondaryPages.filter((p) => p.enabled)}
            isCurrentPage={isCurrentPage}
          />

          <Divider sx={{my: 2, mx: 2.6, borderBottomWidth: 1, backgroundColor: '#DDE0DE'}} />

          <MenuItem
            key={'Settings'}
            page={settingsPage}
            isCurrentPage={isCurrentPage}
          />
        </Box>
      </Box>
    </Drawer>
  );
}

MenuBarV2.propTypes = {
  flags: PropTypes.shape({
    financialReports: PropTypes.bool.isRequired,
    reportWorkspace: PropTypes.bool.isRequired,
  }),
}

export default withLDConsumer()(MenuBarV2);
